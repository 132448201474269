import React, { useState, useEffect } from "react";
import style from "./numbers_about.module.css";
import PropsType from "prop-types"
import icon1 from "../../img/about/icon_1.svg"
import icon2 from "../../img/about/icon_2.svg"
import icon3 from "../../img/about/icon_3.svg"
import { useTranslation } from 'react-i18next';
import { CSSTransition } from 'react-transition-group'
import { useInView } from "react-intersection-observer";



const NumbersAbout = () => {
    const { t } = useTranslation();
    const [isAboutVisible, setAboutVisible] = useState(false);
    const [ref, inView] = useInView({ triggerOnce: true });
    useEffect(() => {
        if (inView) {
            setAboutVisible(true);
        }
    }, [inView]);


    return (
        <div ref={ref} className="wrapper">

            <CSSTransition in={isAboutVisible}
                timeout={2200}
                classNames={{
                    enter: style.numbers_about_wrapper_enter,
                    enterActive: style.numbers_about_wrapper_active
                }}
            >
                <div className={style.numbers_about_wrapper}>

                    <CSSTransition in={isAboutVisible}
                        timeout={2200}
                        classNames={{
                            enter: style.overlay_enter,
                            enterActive: style.overlay_active
                        }}
                    >
                        <div className={style.overlay}></div>
                    </CSSTransition>





                    <div className={style.number_about_element}>
                        <CSSTransition in={isAboutVisible}
                            timeout={900}
                            classNames={{
                                enter: style.number_icon_enter,
                                enterActive: style.number_icon_active_1
                            }}
                        >
                            <img src={icon1} alt="icon" className={`${style.number_icon} ${style.number_icon_little}`} />
                        </CSSTransition>
                        <CSSTransition in={isAboutVisible}
                            timeout={3500}
                            classNames={{
                                enter: style.number_enter,
                                enterActive: style.number_active_1
                            }}
                        >
                        <div className={style.number}>{t("about.aboutNumbers.block1.number")}</div>
                        </CSSTransition>
                        
                        <div className={style.number_description}>{t("about.aboutNumbers.block1.desc")}</div>
                    </div>

                    <div className={style.divider}></div>



                    <div className={style.number_about_element}>
                        <CSSTransition in={isAboutVisible}
                            timeout={2200}
                            classNames={{
                                enter: style.number_icon_enter,
                                enterActive: style.number_icon_active_2
                            }}
                        >
                            <img src={icon2} alt="icon" className={style.number_icon} />
                        </CSSTransition>

                        <CSSTransition in={isAboutVisible}
                            timeout={3500}
                            classNames={{
                                enter: style.number_enter,
                                enterActive: style.number_active_2
                            }}
                        >
                            <div className={style.number}>{t("about.aboutNumbers.block2.number")}</div>
                        </CSSTransition>

                        <div className={style.number_description}>{t("about.aboutNumbers.block2.desc")}</div>
                    </div>


                    <div className={style.divider}></div>


                    <div className={style.number_about_element}>
                        <CSSTransition in={isAboutVisible}
                            timeout={2200}
                            classNames={{
                                enter: style.number_icon_enter,
                                enterActive: style.number_icon_active_3
                            }}
                        >
                            <img src={icon3} alt="icon" className={style.number_icon} />
                        </CSSTransition>

                        <CSSTransition in={isAboutVisible}
                            timeout={3500}
                            classNames={{
                                enter: style.number_enter,
                                enterActive: style.number_active_3
                            }}
                        >
                        <div className={style.number}>{t("about.aboutNumbers.block3.number")}</div>
                        </CSSTransition>

                        <div className={style.number_description}>{t("about.aboutNumbers.block3.desc")}</div>
                    </div>
                </div>
            </CSSTransition>

        </div>

    )
}

NumbersAbout.propTypes = {
    t: PropsType.func
}
export default NumbersAbout;