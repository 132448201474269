import React, { Component, useState, useEffect } from "react";
import { useInView } from 'react-intersection-observer';
import style from "./about.module.css";
import { useTranslation, withTranslation } from "react-i18next";
import PropsType from "prop-types"
import appStyle from "../App.module.css";
import NumbersAbout from "./numbers_about/numbers_about";
import AboutInformation from "./about_inform/about_inform";
import { CSSTransition } from 'react-transition-group'

const HeaderComponent = () => {
    const { t } = useTranslation();
    const [isHeaderVisible, setHeaderVisible] = useState(false);
    const [ref, inView] = useInView({
        triggerOnce: true // Опция, чтобы сработало только один раз
    });

    useEffect(() => {
        if (inView) {
            setHeaderVisible(true);
        }
    }, [inView]);


    return (
        <div ref={ref}>
            <CSSTransition in={isHeaderVisible}
                timeout={1500}
                classNames={{
                    enter: appStyle.titleEnter,
                    enterActive: appStyle.titleEnterActive
                }}
            >
                <h2 className={appStyle.header}>{t("about.aboutHeader")}</h2>
            </CSSTransition>
        </div>
    )
}

class About extends Component {
    render() {
        const { t } = this.props
        return(
            <div id="about" className={style.about_wrapper}>
                <div className={style.overlay}></div>
                
                <HeaderComponent />
                <NumbersAbout/>
                <AboutInformation/>

            </div>
        )
    }
}

About.propTypes = {
    t: PropsType.func
}

export default withTranslation()(About);