const  appHeaderText = {
    eng: {
        aplicationBtn:"Get in touch" ,
        langBtn: "Rus"
    },
    ru: {
        aplicationBtn:"Оставить заявку",
        langBtn: "Eng"
    }
}

export default appHeaderText