const footer = {
    ru: {
        nav_1: "Главная",
        nav_2: "Услуги и кейсы",
        nav_3: "О нас",
        mail: "digital@aroundgroup.ru",
        tel: "8 (968) 744-23-44"
    },

    eng: {
        nav_1: "Main page",
        nav_2: "Services & Cases",
        nav_3: "About us",
        mail: "digital@aroundgroup.ru",
        tel: "8 (968) 744-23-44"
    }
}

export default footer