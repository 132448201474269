const menu = {
    ru: {
        menu_item_1: "Услуги и кейсы",
        menu_item_2: "О нас",
        menu_item_3: "Команда",
        menu_item_4: "Оставить заявку",
        menu_item_5: "Контакты"
    },
    eng: {
        menu_item_1: "Services & Cases",
        menu_item_2: "About us",
        menu_item_3: "Our team",
        menu_item_4: "Get a proposal",
        menu_item_5: "Contact "
    }
}

export default menu



