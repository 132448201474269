import React from "react";
import style from "./promo_buttons.module.css";
import PortfolioBtn from "./portfolio_button";
import ProjectBtn from "./project_button";

const PromoBtn = () => {
    return (
        <div className={style.btn__wrapper}>
            <PortfolioBtn/>
            <ProjectBtn/>
        </div>
    )

}

export default PromoBtn;