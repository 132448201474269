import React, { Component } from "react";
import PropTypes from "prop-types"
import style from "../services_content.module.css"
import { CSSTransition } from 'react-transition-group';

export default class Description extends Component {
    createDescriptions(description, allDescriptionData) {
        const descriptionList = allDescriptionData.map((item, i) => {
            let CSSTransitionInState = false;
            if (item === description) {
                CSSTransitionInState = true;
            }

            return (
                <CSSTransition
                    key={i}
                    in={CSSTransitionInState}
                    timeout={{ enter: 400, exit: 0 }} // Продолжительность анимации в миллисекундах
                    classNames={{
                        enter: style.service_description_enter,
                        enterActive: style.service_description_active,
                        exit: style.service_description_exit,
                        exitActive: style.service_description_exit_active
                    }}
                    unmountOnExit
                >
                    <div className={style.service_description} dangerouslySetInnerHTML={{ __html: description }}></div>
                </CSSTransition>
            )

        })

        return descriptionList
    }

    render() {
        let { description, allDescriptionData } = this.props;

        return (
            <>{ this.createDescriptions(description, allDescriptionData) }</>
        )
    }
}

// export default class Description extends Component {
//     render() {
//         let { description } = this.props;

//         return (
//             <div className={` ${AnimatedtextStyle.animated_text}`}>
// 						<AnimatedText text = {description}/>
//                 </div>
//         )
//     }
// }




Description.propTypes = {
    description: PropTypes.string,
    allDescriptionData: PropTypes.array
}


