import React, { Component } from "react";
import logo from "../img/logo.svg";
import style from "./app-header.module.css";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types"
import i18next from "i18next"

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hamburgerStyle: style.hamburger,
            stripesStyle: style.hamburger__stripes
        }
    }

    animateBtn = (boolian) => {
        if (!boolian) {
            this.setState(() => ({
                hamburgerStyle: style.hamburger,
                stripesStyle: style.hamburger__stripes
            }))
        } else {
            this.setState(() => ({
                hamburgerStyle: `${style.hamburger} ${style.active}`,
                stripesStyle: `${style.hamburger__stripes} ${style.active}`
            }))
        }
    }

    changeLang = async () => {

        if(localStorage.getItem("lang") === "ru" || localStorage.getItem("lang") === null ){
            await i18next.changeLanguage('eng');
            await localStorage.setItem("lang", "eng")
        } else {
            await i18next.changeLanguage('ru');
            await localStorage.setItem("lang", "ru")
        }
    }

    render() {
        const { t, scrollToElement, openMenu } = this.props;
        const { hamburgerStyle, stripesStyle } = this.state

        return (
            <> <div className={style.overlay}></div>
                <header className={style.header}>
                    <a href="#" className={style.logo}><img src={logo} alt="logo" /></a>
                    <div className={style.nav__wrapper}>
                        <a className={style.button} onClick={this.changeLang}>{t("langBtn")}</a>
                        <a className={style.button} href="#form" >{t("aplicationBtn")}</a>
                        <div className={hamburgerStyle} onMouseEnter={() => this.animateBtn(true)} onMouseLeave={() => this.animateBtn(false)} onClick={openMenu}>
                            <span className={stripesStyle}></span>
                            <span className={stripesStyle}></span>
                            <span className={stripesStyle}></span>
                        </div>
                    </div>
                </header>
            </>
        )
    }

}

Header.propTypes = {
    t: PropTypes.func,
    scrollToElement: PropTypes.func,
    openMenu: PropTypes.func
}

export default withTranslation()(Header);