const  crewContent = {
    ru: {
        crewHeader: "Ваша креативная<br> команда",
        crewDesc1: "Наши разработчики, дизайнеры, smm, seo-специалисты, копирайтеры и маркетологи — это эксперты в своей области, обладающие навыками и опытом работы с разнообразными проектами",
        crewDesc2: "Совершенствуем свои знания и следим за последними тенденциями, используем технологичные инструменты"
    },

    eng: {
        crewHeader: "Your creative<br> team",
        crewDesc1: "Our web developers, designers, SMM and SEO specialists, copywriters and marketers are the experts with sufficient skills and work experience at complicated projects in different industries.",
        crewDesc2: "We are always improving our knowledge and ready to adopt the latest trends and techniques."
    }
}

export default crewContent