import React, { useState, useEffect } from "react";
import style from "./like_descr.module.css";
import { useTranslation } from 'react-i18next';
import { CSSTransition } from 'react-transition-group'
import { useInView } from "react-intersection-observer";
import PropTypes from "prop-types"

const LikeDescr = (props) => {
    const { likeNumber } = props;
    const { t } = useTranslation();
    const [isNumberVisible, setNumberVisible] = useState(false);
    const [isDescrVisible, setDescrVisible] = useState(false);

    const [ref, inView] = useInView({ triggerOnce: true });

    useEffect(() => {
        if (inView) {
            setNumberVisible(true);
            setDescrVisible(true);
        }
    })

    return (
        <div ref={ref} className={style.like__descr__wrapper}>

            <CSSTransition in={isNumberVisible}
                timeout={2500}
                classNames={{
                    enter: style.enter,
                    enterActive: style.likeNumberEnterActive
                }}
            >
                <div className={style.like__number}>{likeNumber}{t("likeDescrLike")}</div>
            </CSSTransition>

            <CSSTransition in={isNumberVisible}
                timeout={2700}
                classNames={{
                    enter: style.enter,
                    enterActive: style.likeDescrEnterActive
                }}
            >
                <div className={style.like__descr}>{t("likeDescr")}</div>
            </CSSTransition>

        </div>
    )
}


LikeDescr.propTypes = {
    likeNumber: PropTypes.string
}


export default LikeDescr;