const  promoButtonText = {
    ru: {
        projectBtn: "Начать проект",
        portfolioBtn: "Наше портфолио"
    },
    eng: {
        projectBtn: "Start a project",
        portfolioBtn: "Our portfolio"
    }
}

export default promoButtonText