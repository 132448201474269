import React, { Component } from "react";
import PropTypes from "prop-types";

export default class HeartImg extends Component {
    render() {
        const {heartColor} = this.props;
        return (
            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_152_592)">
                <path d="M5.54342 2.70917C3.82455 2.92711 2.44288 3.77832 1.50531 5.19289C0.5513 6.63213 0.284012 8.55249 0.818588 10.148C1.04887 10.8429 1.45597 11.5502 1.92475 12.0807C2.36886 12.5824 10.663 20.9505 10.7699 21.0081C10.918 21.0821 11.1894 21.0821 11.3374 21.004C11.4649 20.9423 19.8618 12.4549 20.2319 12.019C21.7863 10.1891 21.9919 7.6067 20.7583 5.45606C20.491 4.99139 20.2319 4.65831 19.8084 4.24299C19.06 3.50281 18.0895 2.9929 17.0245 2.77907C16.5762 2.69272 15.4495 2.68038 15.0013 2.76262C14.1213 2.91889 13.2084 3.34243 12.4723 3.93047C12.3613 4.02093 11.9953 4.36635 11.6623 4.69532L11.0578 5.29158L10.4081 4.6542C9.74189 4.00037 9.35946 3.68785 8.85367 3.39589C8.38078 3.12038 7.79275 2.89832 7.22938 2.7873C6.92509 2.72562 5.81893 2.67627 5.54342 2.70917Z" fill={heartColor}/>
                </g>
                <defs>
                <clipPath id="clip0_152_592">
                <rect width="21.0541" height="21.0541" fill={heartColor} transform="translate(0.526855 0.91626)" border="1px solid black"/>
                </clipPath>
                </defs>
            </svg>

        )
    }
}

HeartImg.propTypes = {
    heartColor: PropTypes.string
}


