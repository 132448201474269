const  servicesContent = {
    eng: {
        service1: {
            serviceDescription: `A stylish and functional, perfectly branded website is the recipe for successful promotion and an ideal basis for higher conversion rates.
            Our exclusive solutions for digital design and web development will help to attract you target audience. A business card website or a full-fledged online store?
            We will find a way to all your expectations!
            `,
            serviceList: {
                item1: "Development of landing pages, corporate and business card websites ",
                item2: "Web development with Wordpress, Tilda and other website builders",
                item3: "E-commerce website development",
                item4: "SEO website promotion",
                item5: "E-commerce page design",
                item6: "Development of creative concepts",
                item7: "Design of page layouts and templates ",
                item8: "Programming and CMS integration",
                item9: "Hosting & publishing",
                item10: "Technical support",
                item11: "SERM (Search Engine Reputation Management) "
            }
        },
        service2: {
            serviceDescription: "Our creative and experienced team of digital designers will develop a strong corporate identity, logos or packing to make your products look haunting and inspiring. An innovative graphics, catchy headings and sound navigation will help to attract the attention of your customers where necessary. Your brand will stand out among competitors and stay fresh in the minds of your customers",
            serviceList: {
                item1: "Brand book",
                item2: "Rich content",
                item3: "Product visualization ",
                item4: "3D modeling",
                item5: "Creation of presentations",
                item6: "Designs",
                item7: "Designs for targeted ads",
                item8: "Infographic",
                item9: "Merch design",
                item10: "Web banners design",
                item11: "Promo materials",
                item12: "Web design",
                item13: "Rendering",
                item14: "Page layout design",
                item15: "POSM design"
            }
        },

        service3: {
            serviceDescription: `Eager to get a right start in SMM?
            We will develop the strategy and create a unique content, advertise your products in social media and raise the brand awareness at the highest level possible.
            Together we will built a strong community where every click, like or comment would promote your sales!
            `,
            serviceList: {
                item1: "Design of corporate accounts in social media ",
                item2: "Social media management",
                item3: "Content development (from simple posts to videos)",
                item4: "Social media promotion",
                item5: "Targeted advertising",
                item6: "Youtube & Rutube channels promotion",
                item7: "Influencer marketing (bloggers)  "
            },
            servicePlan: {
                item1:"Competitor analysis",
                item2:"SEO website promotion",
                item3:"Development of development strategy",
                item4:"Creating a content plan",
                item5:"Development of design concept"
            }
        },
        service4: {
            serviceDescription: `Looking for ideas to increase your traffic and attract new customers? Our fully-customized strategies for PPC and professional SEO based on LSI copywriting will get you in the top of the search and increase conversion rate. 
            Would like to get your site in top-10 on relevant keywords in 3-6 months or launch a successful PPC campaign?
            
            We will find the right tools to attract and retain you customers to maintain sustainable growth of your business!
            `,
            serviceList: {
                item1: "PPC advertising",
                item2: "Targeted advertising in social media",
                item3: "Influencer marketing & promotion (bloggers and mass media)",
                item4: "Advertising through Yandex Andertising Network (Dzen, promo-pages, etc…)",
                item5: "Monitoring and analysis of site attendance statistics",
                item6: "Keyword research",
                item7: "Monitoring of most powerful SEO ranking factors",
                item8: "External search promotion and internal optimization of website, works on usability and user experience",
                item9: "LSI-copywriting",
                item10: "Analysis of performance and adjustment of promotional strategy ",
                item11: "Youtube and Rutube channels promotion"
            }
        },
        service5: {
            serviceDescription: `Ready to start with the most effective tool for drawing attention of your audience? We will make first-class videos that will effectively inform about your brand, product or service.`,
            serviceList: {
                item1: "Video filming",
                item2: "Air, water, ground and in-motion videos",
                item3: "Concept & scenario ",
                item4: "Product, branding corporate, instructional and sport videos",
                item5: "Editing, post-production, color correction, translation and adaptation, 2D and 3D graphics, infographics",
                item6: "Adaptation of international advertising content"
            }
        }

        
    },
    ru: {
        service1: {
            serviceDescription: "Стильный, функциональный и идеально сочетающийся с брендом сайт является залогом успешного продвижения и идеальной основой для высокой клиентской конверсии. <span>Наши эксклюзивные решения в области цифрового дизайна и структуры вебсайтов помогут привлечь внимание вашей целевой аудитории.</span> Простой сайт-визитка или полноценный интернет-магазин? Мы найдем способ сделать именно то, что вам нужно!",
            serviceList: {
                item1: "Создание лендингов, корпоративных сайтов, визиток",
                item2: "Разработка сайтов на конструкторе",
                item3: "Разработка интернет-магазина",
                item4: "Разработка, поддержка и SEO-продвижение сайтов",
                item5: "Создание e-commerce страниц",
                item6: "Разработка креативной концепции сайта",
                item7: "Вёрстка страниц и шаблонов",
                item8: "Программирование и интеграция в CMS",
                item9: "Публикация проекта на хостинге",
                item10: "Техническая поддержка существующего сайта",
                item11: "SERM-услуги (управление репутацией в интернете)"
            }
        },
        service2: {
            serviceDescription: "Опытная и креативная команда диджитал-дизайнеров оперативно разработает логотип, фирменное оформление или упаковку, создаст уникальный и запоминающийся стиль вашего продукта. <span>Инновационная графика, яркие заголовки и продуманная навигация помогут удержать внимание посетителей именно там, где это необходимо.</span> Ваш бренд будет выделяться среди конкурентов и оставаться в памяти клиентов.",
            serviceList: {
                item1: "Брендбук",
                item2: "Рич-контент",
                item3: "Создание e-commerce контента",
                item4: "Визуализация продуктов",
                item5: "Услуги 3-д моделирования",
                item6: "Создание презентаций",
                item7: "Креативы для таргетированной рекламы",
                item8: "Разработка инфографики",
                item9: "Визуализация мерча",
                item10: "Создание рекламных баннеров",
                item11: "Подготовка материалов для продвижения",
                item12: "Дизайн веб-сайтов",
                item13: "Рендеринг",
                item14: "Создание макетов страниц",
                item15: "Создание дизайн-макетов для POSM"
            }
        },

        service3: {
            serviceDescription: "Планируете начать серьезную работу с социальными сетями? Мы поможем разработать стратегию, создать высококлассный контент, организовать <span>рекламу в соцсетях</span> и поднять узнаваемость вашего бренда на новый уровень. Вместе мы построим сильное сообщество, где каждый клик, лайк и комментарий внесут свой вклад в реальные продажи!",
            serviceList: {
                item1: "Упаковка аккаунтов в соцсетях",
                item2: "Ведение соцсетей",
                item3: "Разработка релевантного контента (от текста поста до коротких видео)",
                item4: "Полноценная раскрутка соцсетей",
                item5: "Услуги таргетированной рекламы",
                item6: "Оптимизация и модерация Youtube и Rutube",
                item7: "INFLUENCER MARKETING (блогеры)"
            },
            servicePlan: {
                item1:"Анализ конкурентов",
                item2:"SEO-продвижение сайта",
                item3:"Разработка стратегии развития",
                item4:"Создание контент-плана",
                item5:"Разработка дизайн-концепции"
            }
        },
        service4: {
            serviceDescription: "Хотите увеличить трафик и привлечь новых клиентов? Наши эксклюзивные стратегии контекстной рекламы и профессиональное <span>SEO-продвижение</span> на основе LSI позволят вам занять высокие позиции в поиске и увеличить конверсию. Продвижение сайта в топ-10 в течение 3-6 месяце? Разработка контекстной рекламной кампании, приносящей стабильную прибыль?<br><br>Мы найдем способ не только привлечь, но и удержать клиентов, обеспечивая стабильный рост всего вашего бизнеса!",
            serviceList: {
                item1: "Услуги контекстной рекламы",
                item2: "Таргетированная реклама в соцсетях",
                item3: "Реклама у блогеров и СМИ",
                item4: "Реклама на ресурсах Яндекса (Дзен, ПромоСтраницы, …)",
                item5: "Мониторинг и анализ статистики посещаемости сайта ",
                item6: "Сбор семантического ядра и оценка эффективности ключевых запросов (KEI, и др.)",
                item7: "Отслеживание SEO-критериев, максимально влияющих на ранжирование поисковой выдачи",
                item8: "Внутренняя и внешняя поисковая оптимизация, проработка поведенческого фактора и юзабилити сайта",
                item9: "LSI-копирайтинг",
                item10: "Корректировка стратегии продвижения на основе анализа собранной статистики",
                item11: "Продвижение Youtube и Rutube каналов"
            }
        },
        service5: {
            serviceDescription: "Готовы воспользоваться самым эффективным инструментом для привлечения внимания и передачи информации целевой аудитории? Мы создадим для вас по-настоящему высококлассное видео, способное эффективно рассказать о вашем бренде, продукте или услуге.",
            serviceList: {
                item1: "Съемка видеороликов",
                item2: "Виды съёмок: воздух, вода, земля, в движении",
                item3: "Креатив, сценарии, концепция",
                item4: "Виды видеопроизводства: продуктовые, имиджевые, корпо-ративные, обучающие, спортивные, адаптация",
                item5: "Услуги видеомонтажа, пост-продакшн, цветокоррекция, 2D-, 3D-графика, инфографика",
                item6: "Адаптация международного видео-контента"
            }
        }

        
    }
}

export default servicesContent