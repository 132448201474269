const  servicesCaseText = {

    eng: {
        servicesCaseHeader:"Services & Cases" 
    },
    ru: {
        servicesCaseHeader:"Услуги и кейсы" 
    }
}

export default servicesCaseText