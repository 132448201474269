import React, { Component } from "react";
import style from "./services_case_list.module.css";
import { withTranslation } from 'react-i18next';
import PropTypes  from "prop-types"
import ServicesCaseTab from "./services_cases_tab/services_case_tab";


class ServicesCaseList extends Component {
    createList = (data) => {
        const { onActive } = this.props;

        //собираю список табов и передаю пропсами классы, 
        const list = data.map((item, i) => {
            let firstItemClass = "";
            let lastItemClass = "";
            let activeClass = "";

            if(i === 0) {
                firstItemClass = `${style.servicescases__tab_item_first}` 
            }

            if(i === data.length - 1){
                lastItemClass = style.servicescases__tab_item_last
            }

            if(item.active){
                activeClass = style.active;
            }

            


            return (
                <ServicesCaseTab 
                key = {i} className={`${style.servicescases__tab_item} ${firstItemClass} ${lastItemClass}`} 
                text = {item.text} 
                active = { activeClass } // передаю класс активности или пустую строку
                onActive = {onActive} //передаю функцию 
                index = {i} //даю индекс каждому элементу, чтобы передавать его в onActive
                />
            )
        });

        return list;
    }
    render() {
        const { tabListData } = this.props;
        const list = this.createList(tabListData)
        return (
            <ul id="services" className={style.servicescases__tab_list}>
                {list}
            </ul>
        )
    }
}

ServicesCaseList.propTypes = {
    t: PropTypes.func,
    onActive: PropTypes.func,
    tabListData: PropTypes.array
}

export default withTranslation()(ServicesCaseList); 