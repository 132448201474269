import React, { Component } from "react";
import style from "./menu.module.css";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types"
import logo from "../img/logo.svg"
import logo_aroundgroup from "../img/logo_aroundgroup.svg"

class Menu extends Component {


    render() {
        const { t, active, closeMenu } = this.props;
        let menu_wrapper_class = style.menu_wrapper;
        let overlay_class = style.overlay;
        if (active) {
            menu_wrapper_class += ` ${style.menu_wrapper_active}`;
            overlay_class += ` ${style.overlay_active}`;
        }
        return (
            <>
                <div className={menu_wrapper_class}>
                    <div className={style.logo}><a href="http://digital.aroundgroup.ru/"><img src={logo} alt="logo" /></a></div>
                    <div className={`${style.logo} ${style.logo_aroundgroup}`}><a href="http://aroundgroup.ru/" ><img src={logo_aroundgroup} alt="logo" /></a></div>
                    <ul className={style.menu_list}>

                        <li onClick={closeMenu} className={style.menu_list_item}>
                            <a href="#services">{t("menu.menu_item_1")}</a>
                        </li>

                        <li onClick={closeMenu} className={style.menu_list_item}>
                            <a href="#about">{t("menu.menu_item_2")}</a>
                        </li>

                        <li onClick={closeMenu} className={style.menu_list_item}>
                            <a href="#crew">{t("menu.menu_item_3")}</a>
                        </li>

                        <li onClick={closeMenu} className={style.menu_list_item}>
                            <a href="#form">{t("menu.menu_item_4")}</a>
                        </li>

                        <li onClick={closeMenu} className={style.menu_list_item}>
                            <a href="#footer">{t("menu.menu_item_5")}</a>
                        </li>
                    </ul>
                    <div className={style.social}>
                        <div className={style.tel}><a href="tel:+89687442344">8 (968) 744-23-44</a></div>
                        <div className={style.mail}><a href="mailto:digital@aroundgroup.ru">digital@aroundgroup.ru</a></div>
                    </div>


                </div>
                <div className={overlay_class} onClick={closeMenu}></div>
            </>
        )
    }


}


Menu.propTypes = {
    t: PropTypes.func,
    active: PropTypes.bool,
    closeMenu: PropTypes.func
}

export default withTranslation()(Menu)