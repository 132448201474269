import React, { Component } from "react";
import style from "./crew.module.css";
import { withTranslation } from "react-i18next";
import PropsType from "prop-types"
import bg from "../img/crew/bg.svg"
import crew_photo from "../img/crew/crew_photo.png"

class Crew extends Component {
    render() {
        const { t } = this.props
        return(
            <div id="crew" className={style.crew_wrapper}>
                <img src={bg} alt="bg" className={style.crew_bg} />
                <div className={style.crew_text_wrapper}>
                    <h1 className={style.crew_header}  dangerouslySetInnerHTML={{__html: t("crew.crewHeader")}}></h1>
                    <div className={`${style.crew_description} ${style.crew_description_1}`}>{t("crew.crewDesc1")}</div>
                    <div className={`${style.crew_description} ${style.crew_description_2}`}>{t("crew.crewDesc2")}</div>
                </div>
                <div className={style.photo_wrapper}>
                <img src={crew_photo} alt="photo" />
                </div>
            </div>
        )
    }
}

Crew.propTypes = {
    t: PropsType.func
}

export default withTranslation()(Crew);