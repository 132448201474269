import React, { Component } from "react";
import PropTypes from "prop-types"
import style from "./services_content.module.css"
import { CSSTransition } from 'react-transition-group';
import Description from "./sevice_descr/sevice_descr"
import ServiceList from "./service_list/service_list"


class ServicePlan extends Component {
    render() {
        let { listItemPlanText } = this.props;

        return (
            <>{listItemPlanText}</>
        )
    }
}

export default class ServicesContent extends Component {

    constructor(props) {
        super(props);
    }
    createDescription = (content, allDescriptionData) => {
        const description = content.serviceDescription;
        return (
            <>
                <Description description={description} allDescriptionData={allDescriptionData}/>
            </>
        );

    }


    createServiceList(allServiceList, content) {
        const serviceListData = content.serviceList;
        // const serviceList = serviceListData.map((item, i) => {
        //     return (
        //         <ServiceList key={i} listItemText={item} allServiceList={allServiceList} animation_delay={0.2 + i * 0.1}/>
        //     )
        // });
        // console.log(allServiceList)


        const serviceList = allServiceList.map((list) => {
            let CSSTransitionState = false;

            for(let j = 0; j < list.length; j++){
                // console.log(`${list[j]} --- ${serviceListData[j]}`)

                if(list[j] !== serviceListData[j]) {
                    break
                } else {
                    CSSTransitionState = true;
                    break

                }
            }

           return list.map((item, i)=> {
                return (
                    <ServiceList key={i} CSSTransitionState={CSSTransitionState} listItemText={item} allServiceList={allServiceList} animation_delay={0.2 + i * 0.1}/>
                )
            })
        });
        return serviceList;
    }


    createServicePlan(content) {
        const servicePlanData = content.servicePlan;
        const serviceList = servicePlanData.map((item, i) => {
            return (
                <li key={`service_item${i}`} className={style.service_list_item}>{
                    <ServicePlan listItemPlanText={item} />
                }</li>
            )
        });
        return serviceList;
    }

    createServiceImg(imgList, content) {
        const serviceImgList = imgList.map((item, i) => {
            let CSSTransitionInState = false;
            if (item === content.serviceImg) {
                CSSTransitionInState = true;
            }
            return (
                <CSSTransition
                    key={i}
                    in={CSSTransitionInState}
                    timeout={800} // Продолжительность анимации в миллисекундах
                    classNames={{
                        enter: style.service_img_enter,
                        enterActive: style.service_img_active,
                        exit: style.service_img_exit,
                        exitActive: style.service_img_exit_active
                    }}
                    unmountOnExit
                >
                    <div className={style.service_img}><img src={content.serviceImg} /></div>
                </CSSTransition>
            )
        })
        return serviceImgList
    }
    render() {
        const { content, imgList, allServiceList, allDescriptionData } = this.props;


        return (
            <div className={style.services_wrapper}>
                <div className={style.service_img_wrapper}>
                    {this.createServiceImg(imgList, content)}
                </div>

                {/* <div className={`${style.service_img} ${activeImgClass}`}

                ><img src={content.serviceImg} /></div> */}


                <div className={style.service_description_wrapper}>
                    {this.createDescription(content, allDescriptionData)}
                    <ul className={style.service_list}>
                        {this.createServiceList(allServiceList, content)}
                    </ul>

                    <ul className={style.service_list}>
                        {this.createServicePlan(content)}
                    </ul>

                </div>
            </div >
        )
    }
}

ServicesContent.propTypes = {
    content: PropTypes.object,
    showContent: PropTypes.bool,
    leaveContent: PropTypes.bool,
    changeShowContentState: PropTypes.func,
    imgList: PropTypes.array,
    showServiceList: PropTypes.bool,
    allServiceList: PropTypes.array,
    allDescriptionData: PropTypes.array,
    
}




ServicePlan.propTypes = {
    listItemPlanText: PropTypes.string
}



