import React, { useState, useEffect } from "react";
import style from "./form.module.css";
import books from "../img/form/books.png";
import { useTranslation } from 'react-i18next';
import PropTypes from "prop-types"
import { CSSTransition } from 'react-transition-group';
import { useInView } from "react-intersection-observer";
import basicStyle from "../App.module.css"


const ModalThanks = (props) => {
    const { ref, content, closeModal, activeModal } = props;
    const activeClass = activeModal ? basicStyle.modal_active : ""

    return (
        <div ref = {ref} className={`${basicStyle.modal_wrapper} ${activeClass}`}>
            <div className={basicStyle.modal}>
                <h3 className={basicStyle.modal_header}>{}</h3>
                <div className={basicStyle.close_wrapper} onClick={closeModal}><div className={style.close} /></div>
            </div>
        </div>
    )
}

const From = (props) => {
    const { t } = useTranslation()
    const { formRef } = props;
    const [isFormVisible, setFormVisible] = useState(false);
    const [isModalActive, setModalActive] = useState(false);
    const [ref, inView] = useInView({ triggerOnce: true });
    const [refModal, modal] = useInView({ triggerOnce: true });

    const [formData, setFormData] = useState({
        input1: '',
        input2: '',
        input3: '',
        input4: '',
    });

    const closeModal = () => {
        setModalActive(false);
    }

    const openModal = () => {
    useEffect(() => {
        setModalActive(true);
    }, [modal]);
}




    useEffect(() => {
        if (inView) {
            setFormVisible(true);
        }
    }, [inView]);

    const onSubmit = (e) => {
        e.preventDefault();

    }

    // console.log(typeof formRef)
    return (
        <>

        <ModalThanks ref = {refModal} activeModal = {isModalActive}/>

            <div ref={formRef} id="form" className={style.form__wrapper}>
                <div ref={ref} className={style.img__wrapper}>

                    <CSSTransition in={isFormVisible}
                        timeout={1300}
                        classNames={{
                            enter: style.overlay_enter,
                            enterActive: style.overlay_active
                        }}
                    >
                        <div className={style.overlay}></div>
                    </CSSTransition>
                    <CSSTransition in={isFormVisible}
                        timeout={1300}
                        classNames={{
                            enter: style.img__form_enter,
                            enterActive: style.img__form_active
                        }}
                    >
                        <img src={books} alt="books" className={style.img__form} />
                    </CSSTransition>

                </div>


                <CSSTransition in={isFormVisible}
                    timeout={1800}
                    classNames={{
                        enter: style.form_enter,
                        enterActive: style.form_active
                    }}
                >
                    <form className={style.form} id="form" name="form">
                        <CSSTransition in={isFormVisible}
                            timeout={1800}
                            classNames={{
                                enter: style.overlay_form_enter,
                                enterActive: style.overlay_form_active
                            }}
                        >
                            <div className={style.overlay_form}></div>
                        </CSSTransition>

                        <CSSTransition in={isFormVisible}
                            timeout={1800}
                            classNames={{
                                enter: style.form__header_enter,
                                enterActive: style.form__header_active
                            }}
                        >
                            <div className={style.form__header}>{t("formText.formHeader")} </div>
                        </CSSTransition>
                        <CSSTransition in={isFormVisible}
                            timeout={2500}
                            classNames={{
                                enter: style.form__input_enter,
                                enterActive: style.form__input_active_1
                            }}
                        >
                            <input className={style.form__input} type="text" id="name" name="name" placeholder={t("formText.name")} />
                        </CSSTransition>
                        <CSSTransition in={isFormVisible}
                            timeout={2500}
                            classNames={{
                                enter: style.form__input_enter,
                                enterActive: style.form__input_active_2
                            }}
                        >
                            <input className={style.form__input} type="tel" id="tel" name="tel" placeholder={t("formText.phone")} />
                        </CSSTransition>
                        <CSSTransition in={isFormVisible}
                            timeout={2500}
                            classNames={{
                                enter: style.form__input_enter,
                                enterActive: style.form__input_active_3
                            }}
                        >
                            <input className={style.form__input} type="email" id="email" name="email" placeholder={t("formText.mail")} />
                        </CSSTransition>
                        <CSSTransition in={isFormVisible}
                            timeout={2500}
                            classNames={{
                                enter: style.form__input_enter,
                                enterActive: style.form__input_active_4
                            }}
                        >
                            <input className={style.form__input} type="text" id="comment" name="comment" placeholder={t("formText.comment")} />
                        </CSSTransition>


                        {/* <label className= {style.label__text}>
                        <input className= {style.confidatial} type="checkBox" id="confidatial" name="confidatial" />
                        {t("formText.confidatial")}
                        </label> */}
                        <CSSTransition in={isFormVisible}
                            timeout={2700}
                            classNames={{
                                enter: style.form__input_enter,
                                enterActive: style.form__input_active_5
                            }}
                        >
                            <label htmlFor="myCheckbox01" className={style.checkbox__label}>
                                <input className={style.checkbox__input} required type="checkbox" id="myCheckbox01" />
                                <svg className={style.checkbox__icon} viewBox="0 0 22 22">
                                    <rect width="21" height="21" x=".5" y=".5" fill="none" stroke="#fff" rx="3" />
                                    <path className={style.tick} stroke="#fff" fill="none" strokeLinecap="round" strokeWidth="4" d="M4 10l5 5 9-9" />
                                </svg>
                                <a className={style.policy_link} href="https://aroundgroup.ru/konfidenc/" target="_blanck">{t("formText.confidatial")}</a>


                            </label>
                        </CSSTransition>
                        <CSSTransition in={isFormVisible}
                            timeout={2900}
                            classNames={{
                                enter: style.submite_enter,
                                enterActive: style.submite_active
                            }}
                        >
                            <div className={style.submite__wrapper}>
                                <input onClick={(e) => onSubmit(e)} className={style.submit} type="submit" id="submit" name="submit" value={t("formText.submit")} />
                            </div>
                        </CSSTransition>
                    </form>
                </CSSTransition>

            </div>
        </>
    )
}

From.propTypes = {
    formRef: PropTypes.string
}

ModalThanks.propTypes = {
    ref: PropTypes.string,
    content: PropTypes.string,
    closeModal: PropTypes.func,
    activeModal: PropTypes.bool,

}
export default From;