import React, { useState, useEffect } from "react";
import style from "./promo_descr.module.css";
import appStyle from "./../../App.module.css";
import { useTranslation } from "react-i18next";
import { CSSTransition } from 'react-transition-group'
import { useInView } from 'react-intersection-observer';

const PromoDescr = () => {
    const { t } = useTranslation();
    const [isHeaderVisible, setIsHeaderVisible] = useState(false);
    const [isDescrVisible, setIsDescrVisible] = useState(false);
    const [isMotoVisible, setIsmotoVisible] = useState(false);
    const [ref, inView] = useInView({
        triggerOnce: true, // Опция, чтобы сработало только один раз
      });

      useEffect(() => {
        if (inView) {
          setIsHeaderVisible(true);
          setIsDescrVisible(true);
          setIsmotoVisible(true);
        }
      }, [inView]);


    return (
        <div className={style.descr__wrapper}>
        {/* <CSSTransition in={isHeaderVisible} timeout={200} 
                classNames={{
                    enter: style.titleEnter,
                    enterActive: style.titleEnterActive,
                    exit: style.titleExit,
                    exitActive: style.titleExitActive
                  }}
                  unmountOnExit
        > */}
           <h1 ref={ref}  className={`${style.title} ${
            isHeaderVisible ? appStyle.titleEnterActive : appStyle.titleEnter
          }`}>AROUND DIGITAL</h1>
            <div ref={ref} className={`${style.descr} ${
            isDescrVisible ? style.descrEnterActive : style.descrEnter
          }`}>{t("promoDescr")}</div>
            <div  ref={ref} className={`${style.moto} ${
            isMotoVisible ? style.motoEnterActive : style.motoEnter
          }`}>{t("promoMoto")}</div>
        </div>
    )

}

export default PromoDescr;