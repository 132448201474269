import logo_2_1 from "../../img/case/page_2/1.svg"
import logo_5_1 from "../../img/case/page_5/1.svg"
import logo_4_1 from "../../img/case/page_4/1.png"
import logo_1_1 from "../../img/case/page_1/1.png"
import logo_3_1 from "../../img/case/page_3/1.svg"
import logo_1_2 from "../../img/case/page_1/2.png"
import logo_3_2 from "../../img/case/page_3/2.svg"
import logo_1_3 from "../../img/case/page_1/3.svg"

import creo_web_1_1 from "../../img/case/page_1/creo_1_1.png"
import creo_web_1_2 from "../../img/case/page_1/creo_1_2.png"
import creo_design_1_1 from "../../img/case/page_2/creo_1_1.jpg"
import creo_design_2_1 from "../../img/case/page_2/creo_2_1.jpg"
import creo_smm_1_1 from "../../img/case/page_3/creo_1_1.jpg"
import creo_smm_1_2 from "../../img/case/page_3/creo_1_2.jpg"
import creo_adv_1_1 from "../../img/case/page_4/creo_1_1.jpg"
import creo_adv_1_2 from "../../img/case/page_4/creo_1_2.jpg"
import creo_video_1_1 from "../../img/case/page_5/creo_1_1.jpg"
import creo_web_2_2 from "../../img/case/page_1/creo_2_2.jpg"
import creo_web_2_1 from "../../img/case/page_1/creo_2_1.jpg"
import creo_smm_2_1 from "../../img/case/page_3/creo_2_1.png"
import creo_smm_2_2 from "../../img/case/page_3/creo_2_2.png"
import creo_web_3_1 from "../../img/case/page_1/creo_3_1.png"


const casesContent = {
    ru: {
        web: {
            case_1: {
                header: `Услуги разработки и оформления лендинга для агентства недвижимости в Дубае`,
                about: `Оперативная разработка и оформление лендинга для дубайского агентства недвижимости Seaview в период ажиотажного спроса со стороны отечественных покупателей в 2022-2023 гг. позволили компании эффективно отреагировать на уникальную рыночную ситуацию и начать полноценную работу на российском рынке.`,
                profile: `Основанное в 2019 агентство Seaview предоставляет услуги по приобретению эксклюзивной недвижимости в Дубае. Являясь официальным партнером ведущих застройщиков ОАЭ (Sobha Realty, Emaar Propertie, Dubai Multi Commodities Center - DMCC), компания предлагает комплексное сопровождение сделок, в т.ч., страхование (оформление через эскроу счета), а также возможность осуществления покупки в рассрочку или дистанционно, с использованием криптовалюты. До начала 2022 г., основной объем клиентского портфеля агентства составляли граждане США и Западной Европы, однако в связи с резким увеличением числа запросов из РФ, было принято решение обратить пристальное внимание и на российский рынок.`,
                description: `Основной задачей, поставленной Заказчиком перед digital-подразделением компании Around, являлась разработка механизмов эффективного реагирования на ажиотажный спрос со стороны российских клиентов.<br><br>
                Учитывая беспрецедентный уровень «прогрева рынка» и высокую скорость происходящих на нем изменений, вместо изначально рассматриваемой Заказчиком идеи многостраничного русифицированного сайта, наши специалисты предложили разработать оптимизированный под российскую аудиторию одностраничный лендинг с эксклюзивным дизайном, выгодно подчеркивающим качество предлагаемых агентством решений. <br><br>
                По результатам экспертных интервью с потенциальными клиентами, было принято решение сделать лендинг, концепция которого основывалась бы на идее «приятного возвращения домой», в квартиру, способную стать как местом комфортного постоянного проживания, так и крайне перспективной инвестицией на фоне высоких арендных ставок и постоянного роста цен на дубайскую недвижимость.<br><br>
                Окончательный макет лендинга был выполнен в виде набора стильных текстово-графических блоков, представляющих собой захватывающий рассказ о преимуществах покупки жилья в Дубае, с подробным описанием жизненного пространства комплексов недвижимости, детальной планировкой квартир, историей компании, формой захвата и видеоматериалами, активно побуждающими посетителей к контакту с представителями агентства.
                `,
                goals: `Разработка конверсионного лендинга для привлечения целевой аудитории и сбора горячих лидов.
               `,
                results: `Оригинальная концепция, адаптивный дизайн, обеспечивающий гармоничное отображение страницы на всех типах устройств, в сочетании двуязычной версией и люксовым оформлением лендинга позволили подчеркнуть премиальный статус агентства, сформировать необходимое доверие и обеспечить высокий интерес к объектам со стороны потенциальных клиентов. 
                `,
                total: `Пример этого кейса по контекстной рекламе позволяет говорить о том, что даже для стартапа с невысокой известностью, правильно выбранные сроки проведения, адекватный бюджет, комплексный подход к планированию и оперативный анализ тестовой рекламной кампании позволяют добиться более чем впечатляющих результатов. Эффективная контекстная реклама в поисковой выдаче – не миф, а реальность, и наши специалисты знают, как этого добиться.
                Необходимо настроить контекстную рекламу Яндекс Директ? Интересуют услуги ведения контекстной рекламы на постоянной основе? 
                Узнайте больше о наших возможностях и услугах в сфере контекстной рекламы на странице 
                <a href="http://www.digital.aroundgroup.ru/web-design-i-razrabotka-saytov/sozdaniye-landingov/case-seaview">Получите больше информации о создании лендингов на заказ на профильной странице нашего сайта.</a> 
                `,
                logo: logo_1_1,
                creo_1: creo_web_1_1,
                creo_2: creo_web_1_2,
            }, 
            case_2: {
                header: `Создание промо-страницы фестиваля Locals Only`,
                about: `Создание промо-сайта для раскрутки ежегодного музыкально-спортивного фестиваля Locals Only позволило привлечь свыше 133 тысяч посетителей ресурса и обеспечить успешное проведение пяти профильных мероприятий, собравших 72,5 тыс. гостей.`,
                profile: `Фестиваль Locals Only объединяет музыкальные и спортивные интересы современной молодежи под лозунгом «Место встречи музыки и спорта». Мероприятие предполагает большое количество спортивных и культурных активностей, что дает гостям возможность пообщаться со старыми друзьями и завести новых, увидеть выступления популярных музыкальных исполнителей, посмотреть мастер-классы выдающихся спортсменов и попробовать себя в экстремальных видах спорта.`,
                description: `Ежегодный формат проведения подразумевает необходимость удерживания постоянного внимания целевой аудитории в течение длительного периода времени, анонсирования об изменениях в составе музыкально-спортивного лайн-апа, информирования партнеров об условиях присутствия  в проекте.<br><br>
                Для решения этих задач специалистами Around Business Group было предложено создание промо-сайта, способного выгодно подчеркнуть уникальный характер фестиваля, рассказать о его истории и запланированных на текущий год активностях, обеспечить эффективную коммуникацию со спонсорами и способствовать продажам билетов на мероприятие.<br><br>
                C учетом возраста, музыкальных и спортивных предпочтений аудитории, дизайн промо-сайта Locals Only был выполнен в яркой цветовой гамме, с присутствием простых и дерзких графических форм, крупных колор-блоков, использованием коллажной техники и живых фото, символизирующих слом стереотипов и выход за привычные рамки, подчеркивающих присущий мероприятию дух свободы и энергии.<br><br>
                Продвижение промо-сайта осуществлялось с помощью тематических групп в ведущих российских и зарубежных соцсетях, работы с профильными СМИ и сотрудничеством с госструктурами, что позволило сделать фестиваль участником культурных программ, поддерживаемых «Пушкинской картой».
                
                `,
                goals: `Разработка конверсионного лендинга для привлечения аудитории на фестиваль
               `,
                results: `Яркий дизайн и оригинальная концепция промосайта вызвали высокий интерес к мероприятию и позволили добиться следующих результатов:<br><br>
                •	Общее кол-во уникальных посетителей – 133 843<br>
                •	Среднее время присутствия посетителя – 1,17с.<br>
                •	Глубина просмотра – 2,04<br>
                Полученные результаты позволили нам успешно провести 5 ежегодных фестивалей в Москве, Санкт-Петербурге, Сочи, Калининград, привлечь в качестве участников таких музыкальных исполнителей, как Дельфин, Wildways, Кис-кис, Anaconaz, Дора, Grandson, и др., а в качестве спонсоров и информационных партнеров – ВК, Яндекс, телеканал СТС, и другие компании. Общее число гостей мероприятия превысило 72,5 тыс. человек.
                
                `,
                total: `Оригинальное оформление и продуманная концепция позволили нам создать промо-страницу, вызывающую высокий интерес к фестивалю и обеспечивающую устойчивый поток гостей на протяжении всех семи лет существования проекта. 
                Требуется создание и продвижение промо-страниц для раскрутки массовых спортивных, музыкальных или развлекательных мероприятий? 
                Мы создаем промо-сайты, которые действительно способны привлечь ваших клиентов, и всегда готовы предложить эффективные решения по продвижению вашей продукции.
                
                `,
                logo: logo_1_2,
                creo_1: creo_web_2_1,
                creo_2: creo_web_2_2,
            }, 

            case_3: {
                header: `Разработка и дизайн лендинга на Тильде для стартапа в Дубае `,
                about: `Создание лендинга на Тильде для дубайского стартапа KIT-REN, предлагающего аренду устройств для зарядки мобильных электронных устройств, позволило нам сделать клиенту привлекательный и быстрый сайт для привлечения целевой аудитории и партнеров для развития услуги. `,
                profile: `Стартап KIT-RENT предлагает аренду пауэрбанков для автономной зарядки через специальные стойки, размещенные в кинотеатрах, ресторанах и других общественных местах Дубая с использованием мобильного приложения, позволяющего легко находить ближайшую станцию проката, осуществлять оплату сервиса, забирать и возвращать арендованные устройства.  `,
                description: `Для привлечения пользователей к сервису "KIT" возникла необходимость в создании интернет-ресурса, способного рассказать о принципах работы, преимуществах, удобстве и инновационном формате предлагаемой услуги.<br><br>
                С учетом низкой осведомленности потенциальных потребителей об услуге (отсутствии аналогов на локальном рынке) и задачи быстро обеспечить простоту в поиске информации об условиях и местах размещения стоек проката, мы начали с изучения потребностей аудитории и формулирования ценностей и выгоды для потенциальных партнеров. <br><br>
                Процесс разработки включал следующие этапы:<br><br>
                •	Определение целевой аудитории и ключевых функций сайта.<br>
                •	Разработка визуальной концепции, макетов и прототипов.<br>
                •	Реализация функционала, тестирование на различных устройствах и платформах.<br>
                •	Запуск и поддержка: запуск сайта и обеспечение его бесперебойной работы.<br><br>
                По итогам анализа, мы сформировали ключевые требования к функциональности и дизайну, на основании которых было принято решение о разработке сайта в лендинговом формате.<br><br>
                С учетом необходимости создания  интуитивно понятного и привлекательного дизайна в короткие сроки, обеспечения легкости и быстроты загрузки страницы для пользователей с низким зарядом устройств, нашими специалистами была предложена разработка лендинга на Тильде (Tilda), являющейся оптимальным конструктором для решения таких задач.<br><br>
                Дизайн лендинга на Тильде основывался на разработке ярких, но лаконичных зеро-блоков, позволяющих обеспечить привлекательную и интуитивно понятную подачу информации, подчеркнуть удобство использования, инновационный характер и современный стиль сервиса сервиса "KIT". Информационная составляющая была представлена слайдами с рассказом о принципах работы сервиса, интерактивной картой станций проката, FAQ для пользователей, кнопками загрузки мобильного приложения в App Store и Google Play, а также формой для потенциальных партнеров и контактной информации.
                
                
                `,
                goals: `Разработка конверсионного лендинга для продвижения PowerBank
               `,
                results: `Тщательно продуманный  контент и оригинальное оформление лендинга в сочетании с доступным представлением информации позволили вызвать высокий интерес и создать мощный кредит доверия со стороны потенциальных пользователей услуги. 
                
                `,
                total: `Этот кейс свидетельствует, что грамотная концепция и оригинальный дизайн лендинга в сочетании с элегантной простотой решений позволяют быстро и эффективно привлечь внимание целевой аудитории к новым товарам и услугам.<br><br>
                Необходимы комплексные услуги по созданию лендингов? Задумываетесь о создании лендинга на Тильде или другом конструкторе?<br><br>
                Мы всегда готовы разработать оригинальные решения и создать по-настоящему профессиональный лендинг для вашего продукта или услуги на российском или зарубежном рынке.
                
                `,
                logo: logo_1_3,
                creo_1: creo_web_3_1,
            }, 
        },
        design: {
            case_1: {
                header: `Разработка цифрового контента для банка Тинькофф`,
                about: `Разработка цифрового контента для известного банковского бренда в соответствии с заданными параметрами целевой аудитории обеспечил своевременный набор сотрудников для продвижения продукции Клиента в двух российских регионах. `,
                profile: `«Тинькофф банк» - крупная кредитная организация, занимающая 11 место по размеру собственного капитала среди российских банков,  включенная ЦБ РФ в перечень системно значимых кредитных организаций и являющаяся третьим крупнейшим банком страны по количеству активных клиентов. 
                
                Компания активно работает с розничным кредитным портфелем и ориентирована на комплексное развитие банковских онлайн-продуктов для частных клиентов, малого и среднего бизнеса.
                `,
                description: `Структура бизнеса «Тинькофф», подразумевающая отсутствие собственных отделений и дистанционное обслуживание через онлайн-каналы требует активной работы с использованием внешней ресурсной базы. Важнейшим механизмом привлечения потенциальных клиентов являются прямые продажи, осуществляемые в розничных локациях партнеров компании (крупные розничные сети, торговые центры). Необходимость постоянного поиска специалистов по продвижению банковских продуктов потребовала оперативной разработки цифрового контента для быстрого привлечения новых сотрудников для работы консультантами в Москве, Зеленограде и Новороссийске.`,
                goals: `Основной целью проекта являлся дизайн цифрового контента в строгом соответствии с параметрами целевой аудитории компании (мужчины и женщины в возрасте от 18 до 33 лет, способные к активному общению с людьми и заинтересованные в стабильной работе по стандартному (5/2) или сменному (2/2) графику). Задачами нанимаемых сотрудников являлись консультирование по всему спектру банковских продуктов Тинькофф, оформление выдачи дебетовых и кредитных карт потенциальным клиентам.<br><br>
    
                Для реализации проекта, нашими специалистами по digital-маркетингу был проведен ряд экспертных интервью с представителями целевой аудитории Клиента, относящихся к поколениям:<br><br>
    
                •	Миллениалов ( родившиеся во временном промежутке с 1984 по 2000 гг.)<br>
                •	Зумеров (родившиеся во временном промежутке с 2000 г. по н.в.) <br><br>
    
                Изучение ценностей интервьюируемых кандидатов, обладавших необходимыми для активных продаж коммуникативными навыками, позволил выявить важные личностные характеристики, присущие потенциально успешным сотрудникам в обеих группах, а именно, высокую амбициозность, желание «работать больше за большие деньги», ощущение недооценки со стороны текущего работодателя.<br><br>
    
                На основании полученных характеристик, в качестве основных каналов digital-продвижения предлагаемой вакансии, с учетом особенностей восприятия ЦА, была предложена таргетированная реклама в соцсетях и создание коротких промо-роликов для распространения в Telegram.<br><br>
    
                В качестве визуального креатива для соцсетей  диджитал-дизайнером был предложен обобщающий образ «супермена», выполненный в фирменной цифровой палитре «Тинькофф». Сопровождающие образ рекламные слоганы напрямую апеллируют к исповедуемым потенциально успешными кандидатами ценностям, предлагают реализацию их амбиций на основании равноправного партнерства с нанимателем (не «работай на Tinkoff», но «работай с Tinkoff»), подчеркивают чувство собственного  достоинства и предлагая позитивные перемены в социальном статусе (работа не «промоутера», но прежде всего, консультанта крупной компании). 
                Ключевые элементы цифрового дизайна имеют минималистичный и не отвлекающий от сути предложения характер, что позволяет обеспечить максимальную концентрацию на предлагаемых условиях работы.<br><br>
    
                В качестве центрального элемента digital-дизайна для продвижения в Телеграм использовались два коротких промо-ролика, к съемкам в которых были привлечены яркие представители целевой аудитории Клиента. Тщательно подобранные образы  молодых мужчины и женщины излучают оптимизм, надежду, уверенность в завтрашнем дне, призывают к самореализации в новом статусе консультанта одного из крупнейших российских банков. 
                `,
                results: `Использование предложенных Aroundgroup решений в области диджитал-дизайна позволили получить высокий отклик на предлагаемую позицию и осуществить набор новых сотрудников в установленные  Клиентом сроки.`,
                total: `Работа с Тинькофф  позволила нам успешно создать эффективный дизайн рекламных постов для одной из крупнейших российских компаний и получить уникальный опыт взаимодействия при реализации масштабного и территориально-распределенного проекта.`,
                logo: logo_2_1,
                creo_1: creo_design_1_1,

            },
            case_2: {
                header: `Услуги 3D моделирования для компаний банка Тинькофф и известной табачной компании `,
                about: `Услуги 3D моделирования для разработки стендов, рекламных стоек и поп-ап сторов помогли создать технологичный, привлекательный дизайн промо-объектов для банка  Тинькофф и рупной табачной корпорации, увеличив продажи компаний в рамках проводимых рекламных мероприятий на 30% и 23% соответственно.`,
                profile: `Компании Тинькофф и зарубежная табачная компания занимают ведущие позиции в своих отраслях и обладают многолетним опытом работы на российском рынке. `,
                description: `Ориентация компаний на розничного потребителя и отсутствие собственных торговых точек потребовало нестандартных решений для продвижения продукции, способных обеспечить эффективный контакт с потенциальными потребителями.`,
                goals: `Широта аудиторий обеих компаний, обусловленная самой спецификой рынков розничных банковских услуг и табачной продукции, и хороший уровень вовлеченности потребителей подразумевает потенциально высокую эффективность личных контактов с ними. В этой связи, нашими специалистами по продвижению было предложено сконцентрироваться на прямых продажах с использованием таких механизмов продвижения, как рекламные стенды и поп-ап стор («всплывающий магазин») в местах с высокой проходимостью (торговые центры, сети и пр.).
    
                На первом этапе работы мы разработали концепции Pop-Up's с учетом специфики продукции и технических условий конкретных торговых центров, которые получили свое первоначальное воплощение на уровне изображений поп-ап магазинов и стендов с использованием инструментов 3D визуализации.
    
                После оптимизации бюджета проекта и подбора материалов для производства, полученные эскизы поп-ап сторов и стендов были дополнены 3D моделями товаров и торгового оборудования, что позволило оценить конечную привлекательность полученных решений в реалистичных условиях.
    
                После утверждения окончательных вариантов, нашей компанией была подготовлена необходимая проектная документация, организовано производство всех элементов возводимых конструкций, проведен дополнительный подбор и обучение промо-персонала, обеспечена логистика, IT-сопровождение и необходимые бухгалтерские решения.
                `,
                results: `Глобальный запуск проекта по всей территории РФ   для табачной компании (5 различных каналов продаж, свыше 40 мест присутствия в Москве и МО) обеспечил посещаемость рекламных стендов и поп-сторов (по сути, рекламных контактов) на уровне  ≈ 30 тыс. человек ежемесячно, что позволило увеличить текущий уровень продаж компании примерно на 23%.
    
                Запуск проекта аналогичного формата для банка Тинькофф (35 мест присутствия в Москве, Санкт-Петербурге, Новороссийске, Челябинске) позволил сформировать ≈ 10 тыс. рекламных контактов в месяц и обеспечить среднемесячный прирост объема продаж кобрендинговых карт примерно на 30%.
                `,
                total: `Работа с брендами табачной продукции и банковских услуг показала, что рекламные стенды и поп-ап магазины являются эффективным инструментом продвижения продукции массового потребления, рассчитанной на широкую целевую аудиторию, а грамотный дизайн мест рекламного присутствия, продуманное их размещение и дополнительное обучение персонала являются важными условиями успеха подобных проектов.`,
                logo: logo_2_1,
                creo_1: creo_design_2_1,


            }
        },
        smm: {
            case_1: {
                header: `Раскрутка группы в ВК для российского подразделения компании Zelmer`,
                about: `Раскрутка соцсетей для крупного производителя бытовой и кухонной техники увеличила число подписчиков в профильной группе компании на 49,3% (+1358 аккаунта) при средней стоимости привлечения ≈ 4 руб. и минимальном объеме рекламного бюджета (5000 руб.).`,
                profile: `Польская компания Zelmer является крупным производителем техники для кухни и дома с50-летней историей присутствия в странах Центральной и Восточной Европы. Компания активно работает на российском рынке, предлагая свою продукции через собственный интернет-магазин и осуществляя комплексную техническую поддержку через сеть уполномоченных сервисных центров.`,
                description: `Отсутствие собственной розничной сети и ориентир на продажи через интернет требуют от компании повышенного внимания к развитию собственного digital-пространства с упором на взаимодействие с целевой аудиторией через российские социальные сети, прежде всего, раскрутку собственных групп через размещение таргетированной рекламы.  
                `,
                goals: `Основной задачей проекта являлось увеличение числа подписчиков и активности в сообществе бренда в ВК, с минимальным объемом рекламного бюджета в рамках стартового проекта с нашей компанией. Для реализации этого проекта, нашими специалистами по digital-рекламе была предложена акция-розыгрыш с классической механикой привлечения потенциальных участников (подписка на сообщество + лайк+ комментарий) и случайным выбором победителей.<br><br>
                Выбор приза (мясорубки Zelmer ZMM 1525B) осуществлялся на основе анализа отклика аудитории по проводимым ранее рекламным конкурсам. Для продвижения акции, помимо публикации в группе, использовалась таргетированная реклама в vk, показы которой настраивались по основным параметрам уже имеющейся аудитории (сообщество Zelmer»). Дальнейшая настройка рекламы в ВК осуществлялась по ходу кампании, проведенной осенью 2023 г., и продолжавшейся в течение одной недели.
                
               `,
                results: `
                По результатам семи дней работы акции, продвижение группы в ВК позволило достигнуть следующих результатов:<br>
                •	Показы рекламного поста в ВКонтакте: 33 000<br>
                •	Лайки: 1 570<br>
                •	Репосты: 439<br>
                •	Комментарии: 1 872<br>
                •	Новые подписчики в группе Zelmer в ВК: + 1 358  (+49,3% от первоначального числа участников).<br><br>
                Средняя стоимость привлечения подписчика составила 4 рубля, что подчеркивает успешность выбранной стратегии для раскрутки сообщества в ВК, а большое кол-во репостов– о высоком интересе к акции и бренду, готовности её участников предложить своим друзьям узнать о них больше.
    
                `,
                total: `Предложенная механика акции в сочетании со своевременной поддержкой таргетированной рекламой оказалась привлекательной для уже существующих подписчиков группы Zelmer и стала , но  эффективным инструментом для привлечения новой аудитории, увеличения вовлеченности и расширения сообщества бренда в ВКонтакте.<br> 
                На базе минимального рекламного бюджета для раскрутки группы в ВК, нам удалось опровергнуть распространенные предубеждения относительно низкой эффективности продвижения в социальных сетях без значительных финансовых затрат. По результатам проекта, компания Zelmer решила продолжить сотрудничество с Around Business Group  в сфере digital-продвижения, разработки креативов для таргетированной рекламы и создании контента для социальных сетей ВК и ОК. Это сотрудничество продолжается и в настоящее время.<br>
                Заинтересованы в smm-продвижении в ВК? Требуется набрать подписчиков в ВК или ОК? Необходимы услуги ведения соцсетей на постоянной основе?<br>
                Узнайте больше о наших возможностях и услугах в сфере SMM и раскрутке соцсетей на странице 
                <a href="www.digital.aroundgroup.ru/smm-prodvizhenie/ ">www.digital.aroundgroup.ru/smm-prodvizhenie/ </a> 
                `,
                logo: logo_3_1,
                creo_1: creo_smm_1_1,
                creo_2: creo_smm_1_2,
            },

            case_2: {
                header: `Настройка таргетированной рекламы в Телеграм  `,
                about: `Комплексная настройка TG Ads для телеграм-канала поиска работы и релокации в Дубай позволила в течение трех недель привлечь свыше 10 тысяч подписчиков по цене в 0,16 € за пользователя при средней стоимости подписчика на рекламной площадке мессенджера ≈ € 2.`,
                profile: `Телеграм канал @dubai_vakansii посвящен вопросам трудоустройства в Дубае, рассказывает о местных законах, способах релокации, а также выступает связующим звеном между работодателями и соискателями (жителями Дубая или россиянами, переезжающими в ОАЭ). `,
                description: `Развитие ресурса потребовало привлечения профильной аудитории подписчиков, заинтересованных в «горячих» вакансиях, с высоким уровнем зарплатных ожиданий, рассчитывающих на перспективы карьерного роста и развитие профессиональных навыков.<br><br>
                Для привлечения высококачественной целевой аудитории нашими digital-специалистами была предложена стратегия продвижения телеграм канала с использование таргетированной рекламы в TG Ads, а также мероприятия по управлению контентом, в т.ч., создание креативов и текстов для постов, мониторинг новостей в Дубае, постинг и коммьюнити менеджмент.<br><br>
                Для сбора резюме от соискателей нами был разработан специальный чат-бот, способный собирать резюме и другую информацию у пользователей, оповещать подписчиков об актуальных вакансиях, давать ответы на частные вопросы, а также отслеживать отписавшихся, призывая их к обратной подписке.<br><br>
                Настройка рекламы в Телеграм Ads  проводилась в течение 3 недель после запуска кампании; в общем сложности, для продвижения @dubai_vakansii нами было проработано порядка 250 различных каналов.
                 
                `,
                goals: `Привлечение ЦА на канал и сбор базы данных резюме.     
                
               `,
                results: `По итогам тестовой рекламной кампании, нам удалось привлечь в канал свыше 10 тыс. подписчиков. Привлеченная аудитория отличается высоким качеством и максимальным соответствием целевому профилю канала: <br><br>
                •	60% аудитории имеет высшее образование<br>
                •	свыше 60% подписчиков знают английский язык<br>
                •	90% владеют арабским языком<br>
                •	более 80% находятся в поиске работы<br>
                •	40% имеют визу ОАЭ<br>
                •	87% аудитории имеет опыт от 1 года<br>
                •	50% имеют опыт работы за границей<br><br>
                Средняя стоимость подписчиков в телеграм после всех оптимизаций составила всего 0,16 евро за пользователя, что в разы дешевле большинства альтернативных источников трафика.
                `,
                total: `Кейс @dubai_vakansii убедительно доказывает, что профессиональная настройка таргетированной рекламы в Телеграме, в сочетании с продуманной контент-стратегией и использованием чат-бота позволила добиться высокого уровня вовлеченности пользовательской аудитории (ERR ≈56.7% / ERR24 = 25,5%) и привлечь внимание к ресурсу со стороны рекламодателей и партнеров.<br>
                Требуется увеличить число подписчиков в телеграм? Требуются услуги по настройке TG Ads? <br>
                Мы всегда готовы разработать эффективную стратегию продвижения в телеграм и обеспечить быстрое привлечение высококачественных подписчиков в Telegram по действительно конкурентоспособной цене.
                
                `,
                logo: logo_3_2,
                creo_1: creo_smm_2_1,
                creo_2: creo_smm_2_2,
            }
        },
        adv: {
            case_1: {
                header: `Ведение контекстной рекламы директа для стартапа «Мета Meditation»`,
                about: `Оптимизация контекстной рекламы для стартапа «Meta Meditation», продвигающего мобильное приложение для занятий медитацией, снизила стоимость установки программы с 12 000 до 116 руб. `,
                profile: `Компания «Мета Медитации» – технологический стартап, предоставляющий информационные услуги в сфере духовного и физического самосовершенствования, обучения медитативным практикам, разработанным известным гуру и учителем йоги Феликсом Паком. Коммуникация с целевой аудиторией, подписка и проведение уроков медитации осуществляются через мобильное приложение (программу для медитаций), устанавливаемое на устройства клиентов. `,
                description: `Цифровой формат стартапа требует поиска оптимальных инструментов digital-продвижения, позволяющих эффективно увеличить число загрузок приложения и обеспечить приемлемую стоимость привлечения новых пользователей. В этой связи, основной задачей проекта стала разработка эффективной стратегии контекстной рекламы в поиске Yandex.<br><br>
                Для реализации этой задачи, нашими специалистами была разработана тестовая рекламная кампания со стартовым бюджетом 100 тыс. руб. в месяц. Настройка поисковой рекламы в Яндекс Директ основывалась на принципах ручного управления ставкам и ежедневного мониторинга расходов клиента. <br><br>
                Первоначальный подбор ключевых фраз базировался на оценке показателей фразовой и точной частотности, классификации по уровню интента, с учетом оценки уровня конкуренции по конкретным ключам (показатель KEI).<br><br>
                Ведение контекстной рекламы директа персональным менеджером, с ежедневными отключениями неэффективных ключей и объявлений, и переносом расходов бюджета на эффективные связки за 4 месяца позволило протестировать свыше 200 ключевых запросов и 8 вариантов рекламного текста.
                `,
                goals: `Привлечение пользователей, заинтересованных в медитации и саморазвитии во всех странах СНГ.<br>
                Увеличение осведомленности о приложении Meta Meditation среди целевой аудитории.
               `,
                results: `За 4 месяца кампании, с мая по август 2023 г., оптимизация контекстной рекламы в Яндекс Директ позволила получить следующие результаты:<br>
                •	Общее количество показов тестируемых объявлений составило 18 547 455, количество кликов -15 690, при средней стоимости клика 25,49 руб.<br>
                •	Средняя стоимость установки приложения снизилась с 12 000 руб. в начале тестирования до 116 руб. по его завершении, т.е., примерно в 100 раз.
                `,
                total: `Пример этого кейса по контекстной рекламе позволяет говорить о том, что даже для стартапа с невысокой известностью, правильно выбранные сроки проведения, адекватный бюджет, комплексный подход к планированию и оперативный анализ тестовой рекламной кампании позволяют добиться более чем впечатляющих результатов. Эффективная контекстная реклама в поисковой выдаче – не миф, а реальность, и наши специалисты знают, как этого добиться.
                Необходимо настроить контекстную рекламу Яндекс Директ? Интересуют услуги ведения контекстной рекламы на постоянной основе? 
                Узнайте больше о наших возможностях и услугах в сфере контекстной рекламы на странице<a href="digital.aroundgroup.ru/prodvijenie-sayta-v-internete/uslugi-kontextnoy-reklamy/ ">digital.aroundgroup.ru/prodvijenie-sayta-v-internete/uslugi-kontextnoy-reklamy/ </a> 
                `,
                logo: logo_4_1,
                creo_1: creo_adv_1_1,
                creo_2: creo_adv_1_2,

            }
        },
        video: {
            case_1: {
                header: `Съемка корпоративного фильма о компании Керхер Россия  `,
                about: `Съемка документального фильма «Бой с пандемией: Чистая правда», посвященного работе компании Karcher во время эпидемии коронавируса позволила эффективно продемонстрировать возможности клининговой техники компании и выдающиеся личностные качества её сотрудников, собрав свыше 332 тысяч просмотров на Youtube.`,
                profile: `Компания «Керхер Россия» является дочерней организацией и официальным российским подразделением компании Alfred Kärcher SE & Co. KG, являющейся признанным мировым лидером на рынке клининговой, уборочной и коммунальной техники, аппаратов высокого и сверхвысокого давления, пароочистителей, систем водоочистки, моечных установок, пылесосов, чистящих принадлежностей и пр., с ежегодным оборотом свыше 3 млрд. евро и представительствами в 80 странах мира.`,
                description: `Начавшаяся в 2020 г. пандемия, затронувшая практически все страны мира, включая и Россию, вызвала устойчивый интерес широких слоев населения не только к медицинским аспектам вопроса, но и к проблемам уборки, чистки и дезинфекции. Активное участие российского подразделения Клиента в мероприятиях по борьбе с коронавирусом потребовало информационно-рекламного освещения этой работы в актуальном и современном формате.`,
                goals: `Для эффективной демонстрации технологического и общественно-социального вклада российского отделения (ООО «Керхер») в борьбу с коронавирусом, нашим digital-подразделением было предложено использовать формат документальной съемки. В сценарии было предложено сделать особый акцент на морально-этических качествах сотрудников компании Karcher и их самоотверженной, с риском для собственной жизни, работе по организации и проведению дезинфекционных мероприятий, обеспечению гигиенической чистоты в самых сложных условиях.<br><br>
                В общей сложности, съемка корпоративного фильма потребовала участия использования 19 локаций, в т.ч. коммерческих объектов, транспорта и больниц, находящихся в «красной зоне», 5 поездок в различные муниципальные образования, 467 фото бэкстейджа, участия 30 героев и свыше 600 вопросов к ним во время интервью.
               `,
                results: `По результатам 127 часов документальной видеосъемки, нашей продюсерской командой было создано два корпоративных фильма:<br>
                •	Основной фильм, «Бой с пандемией: Чистая правда», общей длительностью 53 мин. 05 сек.<br>
                •	Трейлер к основному фильму, «Клининг Vs Covid – война с невидимой смертью», общей длительностью 1 мин. 14 сек.<br><br>
                Для эффективного размещения этих документальных фильмов на видеохостинге Youtube, нашими seo-специалистами была проведена оптимизация заголовков и описаний к видео. В результате, общее кол-во просмотров отснятых материалов на начало 2024 г. составило ≈ 332 тыс., из которых 138 тыс. пришлось на основной фильм.
                `,
                total: `Предложенная специалистами нашей компании съемка рекламного фильма в формате документального видео, как и конечный результат работы, получили высокую оценку со стороны нашего Клиента.
    
                «Отдельную благодарность хотим выразить за съемки и реализацию глобального проекта: Производство документального фильма «Бой с пандемией: чистая правда». Во время пандемии коллеги смогли оперативно реализовать продюсирование, режиссирование, съемки в различных локациях, включая «красную зону», постпродакшн фильма и организация показа в кинозале, который никого не оставил равнодушным и привлек положительное внимание к клинингу и бренду.
                ООО «Керхер рекомендует компанию ООО «А-маркетинг» как надежного и долгосрочного партнера, прикладывающего все свои ресурсы и знания в решении поставленных задач»<br><br>
    
                Работа с компанией Керхер по этому проекту позволила нам создать высококачественный презентационный фильм о компании в экстремальных условиях съемок и последующего производства. Заинтересованы в съемке рекламного фильма о вашем бизнесе? Необходимо снять корпоративный фильм?<br><br>
    
                Узнайте больше о наших возможностях и услугах в сфере производства видеоконтента  на странице <a href="digital.aroundgroup.ru/sozdanie-videokontenta">digital.aroundgroup.ru/sozdanie-videokontenta</a> 
    
                `,
                logo: logo_5_1,
                creo_1: creo_video_1_1,


            }
        },
        headers: {
            summary: `Резюме проекта`,
            profile: `Профиль Клиента`,
            description: `Описание проблемы`,
            goals: `Цели и задачи проекта`,
            results: `Полученные результаты`,
            total: `Что в итоге?`,
            casesDetails: "Подробнее"
        }
    },
    eng: {
        web: {
            case_1: {
                header: `Landing page design and production for Dubai real estate agency`,
                about: `Landing page design and production for Seaview (Dubai real estate agency) to match the high demand from the Russian customers during 2022-2023 helped the company to react to the unique market conditions and start full operation in the region.`,
                profile: `Seaview real estate agency was founded in 2019 to provide a full range of services in the purchase of luxury property in Dubai. As an official partner of the leading developers of UAE (Realty, Emaar Propertie, Dubai Multi Commodities Center – DMCC) the company offers integrated support on real extate transactions, incl. insured escrow accounts, possibility of purchasing in installments, remote purchasing and payments by crypto currency.<br><br>
                The major part of the client portfolio had been represented by customers form USA and Western Europe but after the beginning of 2022 the increased demand from Russia became the priority.
                `,
                description: `The main task from the Client was to find effective tools to match the high demand from the Russian customers. <br><br>
                Given the extremely hot market and unique speed of changes at the niche, instead of the Client’s ideas for a multipage website our digital marketing specialist suggested to launch an exclusively designed landing page optimized for the Russian audience to highlight the quality of offers from the agency.
                A set of expert interviews with potential customers gave an idea for landing page with the concept of «a pleasant return» to the home flat, which is both a place of comfortable permanent residence and a prospective investment amid high rental rates and price for Dubai property.<br><br>
                The final version of the landing page was designed as a set of graphic and text blocks to tell a mind-blowing story about the advantages of Dubai property with detailed descriptions of living space and layout, videos and lead capture forms to motivate potential customers to contact the agency. 
                
                `,
                goals: `Development of a conversion landing page to attract the target audience and collect hot leads.
               `,
                results: `Original concept with responsive design for correct display of the landing page on all types of devices, bilingual version and luxury layouts perfectly emphasized the premium status of the agency and created the necessary trust to ensure high interest in the objects from potential customers.
                `,
                total: `This case shows that in the hot market, selection of optimum format of digital communications, creation of original concept and unique design are essential to get the prime attention of a new target audience and ensure rapid growth of potential customers.
                Need an urgent landing page design? A selling landing page? A single-page landing to estimate potential demand?<br><br>
                Our specialists will always help to create an original concept and premium landing page designs for your products or services. 
                
                `,
                logo: logo_1_1,
                creo_1: creo_web_1_1,
                creo_2: creo_web_1_2,
            }
        },
        design: {
            case_1: {
                header: `Social media content creation for Tinkoff bank`,
                about: `Development of digital content for a popular banking brand (Tinkoff) to reach the target audience of potential employees provided effective hiring of promotional personnel to launch a new product in two Russian regions.   `,
                profile: `Tinkoff bank (Tinkoff Credit Systems) is a large credit company which occupies eleventh place among largest Russian Banks in terms of own capital and third place by the number of active customers while listed by Central Bank of Russia as a systemic banking organization.
                Tinkoff actively develops its retail loan portfolio and online banking products for private customers, small and medium-sized companies.
                
                `,
                description: `The company’s business structure, which implies no retail branches and is focused on distant (online) banking calls for active measures for promotion through partner channels. The key tool for attracting potential customers is direct selling made in public locations, such as shopping centers and retail networks.
                The need for constant hiring of promoters-consultants «on the ground» in this particular case required a social media content to raise interest of potential candidates to fill vacancies for Moscow, Zelenograd and Novorossiysk cities as soon as possible.
                `,
                goals: `The key goal of the project was to develop a digital content that would fit the target audience of men and women aged 18-33 with good communication skills looking for a steady job with a standard working schedule (5/2) or a shift work (2/2). The job responsibilities for the position are to consult potential clients on the current range of Tinkoff banking products and to issue debit and credit cards for new customers.
                To implement this project at due course our digital marketing specialist provided a set of expert interviews with the representatives of the target audience, which in fact represented two different demographic cohorts:
                •	Millenials (born between 1984 and 1996)
                •	Zoomers (born between 1997 by 2012)
                Our study of prominent candidates with appropriate communicative skills from both cohorts allowed to find the common values, such as higher ambitions, a desire “to work more for more money” and a certain dissatisfaction by the current employer.
                These characteristics, together with high level of involvement and trust for social media proposed to use targeted advertising and promo clips in Telegram as the key instruments for digital promotion of the position.
                The visuals for the social media were designed to deliver an image of «Superman-consultant» in corporate colors of Tinkoff. The slogans were set to appeal the ambitions and values of candidates to promote the sense of self-realization, dignity and mutually beneficial partnership with the employer (“work with Tinkoff, not “work for Tinkoff”) as well as to hint for a higher employment status (not a “promoter”, but “consultant”).
                The main elements of design are minimalistic to concentrate on the essence of the job advantages. 
                For promotion in Telegam we filmed two short promo clips with typical representatives of the target audience. Carefully selected actors (man and woman) radiate optimism, hope and confidence in the future while calling for a prominent career of consultant in one of the largest banks of Russia.
                `,
                results: `The digital design for social media and Еelegram produced by our specialists for Tinkoff resulted in high response rate from potential candidates for the vacancies and successful hiring of required number of employees in due time.`,
                total: `Our cooperation with Tinkoff bank resulted in effective design of advertising posts in social media for one of the biggest Russian companies and allowed to get a through experience of work on a large-scale and geographically distributed project.
                `,
                logo: logo_2_1,
                creo_1: creo_design_1_1,


            },
            case_2: {
                header: `3D modeling services for Tinkoff bank and a large tobacco company `,
                about: `3D modeling services by Around helped to create attractive designs of promotional displays and pop-up stores for retail advertising units of Tinkoff and a large tobacco manufacturer thus increasing their sales by 24-30%.`,
                profile: `Tinkoff bank and a large tobacco company are the leaders of the industries with decades of experience on the Russian market.`,
                description: `The basic focus on retail sales and the lack of branded retail outlets required ingenious promotional solutions to maintain an effective reach of the potential customers.`,
                goals: `The broadness and high consumer involvement of the target audiences of retail banking and tobacco consumption offer a good prospect for direct contacts with the customers.
                Thus our marketing specialists suggested focusing on direct sales through pop-up stores and promotional displays located at the places of high traffic flow of potential customers, such as shopping malls and mass retailers.<br><br>
                We started with the creation of the concepts of Pop-Ups to meet the products requirements and technical conditions of the selected shopping malls and retail chains that resulted in basic 3D models of pop-up stores and displays.<br><br>
                After budgeting the project and selection of the materials, these basic 3D models were enhanced with the visualization of the products and in-store equipment to evaluate the attractiveness of different variants in close-to real conditions.<br><br>
                After approval of the final designs our specialists developed all the necessary project documentations and managed the final production of the construction elements, as well as hiring of the promo personal, logistics & IT support for accounting and data transfer.
                
                `,
                results: `The implementation of the project for the tobacco company at the country level (5 different channels of presence and more than 40 promotional units in Moscow Region) gave approx. 30 000 visits of potential customers per month, that helped to increase the company’s sales by 24% <br><br>
                The implementation of the project for Tinkoff Bank (35 places of presence in Moscow, St. Petersburg, Novorossiysk, Chelyabinsk) allowed to make about 10 000 contacts with potential customer per month and raise the sales of cobranding payment cards by 30%.
                
                `,
                total: `Our experience of work with tobacco and banking brands clearly showed that displays and pop up stores are effective promotional tools for mass market products with a broad target audience, meanwhile an attractive design, good location and well-trained promo personnel are the key conditions for success.<br><br>
                Got interested in promotion of your products by establishing pop-up stores? Looking for 3D modeling or rendering of your products and in-store equipment? 3D design of retail spaces?
                `,
                logo: logo_2_1,
                creo_1: creo_design_2_1,


            }
        },
        smm: {
            case_1: {
                header: `Promotion of  Zelmer company’s community in Russian social network VK `,
                about: `Social media promotion program for a large manufacturer of kitchen and household appliances with a budget of 5000 rubles increased the subscribers of the company’s group in Russian social network VK by 49.3% (+1358 new subscribers) at the acquisition cost of 4 rubles per 1 subscriber.`,
                profile: `Zelmer is a large Polish manufacturer of kitchen and household appliances with more than 50 years of market presence in Central and Eastern Europe. The company is active in the Russian market and sells its products through branded online store with a comprehensive technical support by authorized service centers.`,
                description: `The lack of branded retail stores and online sales being the main distribution channel require an emphasis on digital promotion focused to reach the key audience through Russian social media and growing the company’s social networking communities with the help of targeted advertising. 
                `,
                goals: `The goal of the project was to raise the number of subscribers for the main digital community of the company in VK social network with a minimal starting budget to check the possibilities of further cooperation with our company.
                To achieve this goal our digital marketing specialist suggested to run a social media contest with random selection of winners to attract potential subscribers among the VK users with the subscription, like and comment as the entry contest requirements.
                The choice of the particular prize for the contest (Meat mincer Zelmer ZMM 1525B) was made in accordance with the preliminary analysis of customers’ reactions and involvements in previous contests. To promote the contest we announced it in the community and launched a targeted advertising adjusted for the profile of the Zelmer group with further tuning at the time of the campaign (which lasted for one week in autumn of 2023).
                
                
               `,
                results: `
                One week targeted advertising campaign to promote Zelmer community in VK achieved the following results:
                •	Number of views of the ad post in VK: 33 000
                •	Number of likes: 1570
                •	Reposts: 439
                •	Comments: 1 872
                •	New subscribers to the Zelmer VK community: + 1 358 (49.3% rise from the initial number of sunscribers)
                The subscriber acquisition cost (SAC) settled down at 4 rubles that can be considered a success for the implemented strategy of growing VK community. The large number of reposts indicated great interest for the contest and the brand as well as the willingness of participants to promote Zelmer to their friends. 

    
                `,
                total: `The parameters of the contest and additional support by targeted advertising campaign made it really attractive instrument for encouraging current subscribers, attracting new members for of Zelmer VK community while raising general brand awareness in the social media.
                This case of social media promotion with an extremely small budget debunks the myth of inefficiency of low-budget advertising. As result of this project, Zelmer decided to continue cooperation with our company for digital marketing, design and content management for social networks VK and OK. This mutually beneficial cooperation is still ongoing.
                Got interested in promotion through VK social network? Want to grow an effective community in VK or OK or manage your groups in social media on a permanent basis?
                
                `,
                logo: logo_3_1,
                creo_1: creo_smm_1_1,
                creo_2: creo_smm_1_2,
            }
        },
        adv: {
            case_1: {
                header: `Set up and maintenance of contextual advertising in Yandex Direct`,
                about: `Optimization of contextual advertising campaign in Yandex for a prominent startup project (meditation app) reduced the average installation cost from 12 000 to 166 rubles. `,
                profile: `Meta Meditations is a startup tech company providing informational services for spiritual and physical self-improvement by learning meditation practices developed by a famous yoga guru Felix Pak.
                Subscription, conduction of lessons and communication with the target audience are managed via mobile app installed on client devices.
                `,
                description: `The tech format of the startup requires to find relevant instruments of digital promotion to raise the number of downloads of the app and get a moderate acquiring cost for new subscribers. This goal in turn called for setting up of an effective contextual ads campaign in Yandex search engine.
                To manage this task our specialist developed a test advertising campaign with a starting budget of 100 000 rubles per month. Adjusting of the campaign was implemented by manual bid management and daily monitoring of the expenses.
                Initial keyword research and selection was implemented on assessment of the ratio of phrasal and exact keyword frequencies, classification by type of consumer intent and Keyword Effectiveness Index (KEI).
   
                
                `,
                goals: `The further maintenance of the campaign by a personal manager providing daily disabling of ineffective keywords and ads, and while encouraging effective set ups resulted in testing of more than 200 keyword phrases and 8 variants of advertising text.
               `,
                results: `The four month campaign started in the May of 2023 and ended in August resulted in the following figures:
                •	 Number of views of the tested ads in Yandex search: 33 000
                •	Number of likes: 15 690
                •	PPC: 25, 49 rubles
                •	Average installment cost dynamic: a reduction from 12000 to 116 rubles, i.e. by 100 folds 
                
                `,
                total: `This case on contextual advertising campaign in Yandex shows that even a startup with low awareness but sufficient trial budget may achieve excellent results by through planning, analysis and timely review of test campaign. The effective contextual advertising is not a myth but the reality that we constantly create for our clients.
                Looking for a company to set up and maintain an advertising campaign in Yandex Direct on the permanent basis?
                
                `,
                logo: logo_4_1,
                creo_1: creo_adv_1_1,
                creo_2: creo_adv_1_2,
            }
        },
        video: {
            case_1: {
                header: `Corporate video production for Russian branch of Alfred Kärcher SE & Co. KG`,
                about: `Filming of the corporate documentary dedicated to the performance of Russian branch of Karcher during Covid-19 pandemic effectively demonstrated the advantages of the company’s cleaning equipment and promoted outstanding personal qualities of its employees that resulted in about 332 000 views on Youtube`,
                profile: `«Karher Russia» company is the Russian subsidiary and official representative of Alfred Kärcher SE & Co. KG, which is considered a global market leader in cleaning and municipal equipment, high pressure and ultra-high pressure machines, steam cleaners, water purification systems, wash stations, vacuum cleaners, cleaning accessories, etc., with the annual turnover of over €3 billion  and representative offices in 80 countries.`,
                description: `The pandemic of 2020 that severely affected the majority of countries, incl. Russia provoked a great interest of general public for not only medical, but also cleaning and disinfection issues.
                The active participation of Russian Karcher branch in coronavirus fighting efforts required an appropriate information and advertising campaign to fit the general agenda in an ambitious and original way.
                `,
                goals: `For effective demonstration of technological and social contribution of Russian Karcher branch in fighting Covid-19 our digital department suggested an idea of documentary filming. The scenario was to highlight moral and ethical qualities of the company’s employees and their dangerous work aimed for disinfection to provide hygienic cleanliness under the toughest circumstances ever.
                The filming of the documentary required visiting 19 locations, incl. commercial property, transports, red zones of hospitals, 5 trips to various municipalities, 467 backstage photos and interviews of 30 persons with over 600 questions.
                 
                
               `,
                results: `The initial documentary filming amounted to 127 hours of videos and resulted in producing two corporate films:
                •	The main film «Fighting the Pandemic: the pure truth» with total duration 53 min. 05 sec.
                •	The trailer for the main film «Cleaning Vs. Covid-19» with total duration 53 min. 05 sec.
                
                For effective promotion of the films on Youtube our specialist made an amount work for proper optimization of titles and description for the videos. As a result, by the beginning of 2024 we reached 332 000 views, with 138 000 views of the main film.
                
                `,
                total: `The suggested format of advertising filming based on documentary and the final result were highly praised by our Client.
                «We would like to make special thanks for the producing and promotion of such a big documentary project as «Fighting the Pandemic: the pure truth». During the pandemic our colleagues managed to provide production, direction and shooting in different locations, including red zones, maintain post-production and organize the premiere that left no one indifferent while focusing positive attention to the brand and the cleaning industry.
                Karcher Russia LLC would like to recommend A-marketing LLC as a reliable and long term partner that mobilize all the resources and expertise in solving the tasks of the clients».
                
                Our work with Karcher on this project resulted in creation of a high quality presentation film about the company in extremely difficult conditions of both shooting and production.
                Would like to make a promotional film about your business or company? Need to make a corporate film? 
                
                `,
                logo: logo_5_1,
                creo_1: creo_video_1_1,


            }
        },
        headers: {
            summary: `Project Summary`,
            profile: `Client Profile`,
            description: `Description of the problem`,
            goals: `Project goals and objectives`,
            results: `Results`,
            total: `What's the result?`,
            casesDetails: "Details"
        }
    }
}

export default casesContent