import React, { useState, useEffect } from "react";
import style from "./about_inform.module.css";
import { useTranslation } from "react-i18next";
import PropsType from "prop-types";
import adventage_1 from "../../img/about/adventage_1.svg";
import adventage_2 from "../../img/about/adventage_2.svg";
import adventage_3 from "../../img/about/adventage_3.svg";
import wave from "../../img/about/bg_wave_vertical.svg";
import { CSSTransition } from 'react-transition-group'
import { useInView } from "react-intersection-observer";

const AboutInformation = () => {
    const { t } = useTranslation();
    const [isAboutInformVisible, setAboutInformVisible] = useState(false);
    const [ref, inView] = useInView({ triggerOnce: true });
    useEffect(() => {
        if (inView) {
            setAboutInformVisible(true);
        }
    }, [inView]);

    return (
        <div ref={ref} className={style.about_information_wrapper}>
            {/* <div className={style.wave}> */}
            <img src={wave} className={style.wave} alt="" />
            {/* </div> */}
            <CSSTransition in={isAboutInformVisible}
                timeout={900}
                classNames={{
                    enter: style.about_description_enter,
                    enterActive: style.about_description_active
                }}
            >
                <div className={style.about_description}>
                    {t("about.aboutDesc")}
                </div>
            </CSSTransition>


            <div className={style.about_adventages_wrapper}>



                <CSSTransition in={isAboutInformVisible}
                    timeout={900}
                    classNames={{
                        enter: style.adventage_enter,
                        enterActive: style.adventage_active_1
                    }}
                >

                    <div className={style.adventage}>
                        <div className={style.img_wrapper}>
                            <img src={adventage_1} alt="adventage" className={style.adventage_img} />
                        </div>
                        <div className={style.adventage_text} dangerouslySetInnerHTML={{ __html: t("about.aboutAdventage1") }}></div>
                    </div>
                </CSSTransition>



                <CSSTransition in={isAboutInformVisible}
                    timeout={900}
                    classNames={{
                        enter: style.adventage_enter,
                        enterActive: style.adventage_active_2
                    }}
                >
                    <div className={style.adventage}>
                        <div className={style.img_wrapper}>
                            <img src={adventage_2} alt="adventage" className={style.adventage_img} />
                        </div>
                        <div className={style.adventage_text} dangerouslySetInnerHTML={{ __html: t("about.aboutAdventage2") }}></div>
                    </div>
                </CSSTransition>


                <CSSTransition in={isAboutInformVisible}
                    timeout={900}
                    classNames={{
                        enter: style.adventage_enter,
                        enterActive: style.adventage_active_3
                    }}
                >
                    <div className={style.adventage}>
                        <div className={style.img_wrapper}>
                            <img src={adventage_3} alt="adventage" className={style.adventage_img} />
                        </div>
                        <div className={style.adventage_text} dangerouslySetInnerHTML={{ __html: t("about.aboutAdventage3") }}></div>
                    </div>
                </CSSTransition>
            </div>
        </div>
    )
}

AboutInformation.propTypes = {
    t: PropsType.func
}
export default AboutInformation;