import React, { useEffect, useState } from "react";
import planetImg from "../img/promo_planet_2.png"
import style from "./promo.module.css";
import PromoDescr from "./promo_descr/promo_descr";
import PromoBtn from "./promo_buttons/promo_buttons";
import LikeSection from "./like_section/like_section";
import { useInView } from 'react-intersection-observer';

const Promo = () => {
    return (
        <section className={style.promo}>
            <div className={style.overlay}></div>
            <PromoDescr />
            <PromoBtn />
            <LikeSection />
            <ParallaxComponent />
        </section>
    )

}




const ParallaxComponent = () => {
    const [position, setPosition] = useState({ x: 0, y: 0 });

    const handleMouseMove = (event) => {
        const mouseX = event.clientX;
        const mouseY = event.clientY;
        setPosition({ x: mouseX, y: mouseY });


    };

    const [isImgVisible, setImgVisible] = useState(false);
    const [ref, inView] = useInView({
        triggerOnce: true, // Опция, чтобы сработало только один раз
      });

      useEffect(() => {
    
        window.addEventListener('mousemove', handleMouseMove);
    
      }, []);
      
      

      useEffect(() => {
          if (inView) {
            setImgVisible(true);
          }
    
      }, [inView]);
      


    const parallaxStyle = {
        transform: `translate(${position.x * -0.1}px, ${position.y * -0.15}px)`, 
    };

    return (

        <img
        src={planetImg}
        style={{ ...parallaxStyle }}
        alt="planet"
        ref={ref}
        className={`${style.bg_img} ${
          isImgVisible ? style.imgActive : style.imgEnter
        }`}
      />
    );
};


export default Promo;