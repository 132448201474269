import React from "react";
import style from "./footer.module.css";
import logo from "../img/logo.svg"
import email from "../img/footer/EMAIL.svg"
import telegram from "../img/footer/TELEGRAM.svg"
import vk from "../img/footer/VK.svg"
import youtube from "../img/footer/YOUTUBE.svg"
import { useTranslation } from "react-i18next"

const Footer = () => {
    const { t } = useTranslation();

        return(
            <footer id="footer" className = {style.footer}>
                <div className={style.logo__wrap}>
                    <a href="https://digital.aroundgroup.ru/"><img src = {logo} alt="logo" className={style.logo__img}/></a>
                </div>

                <ul className={style.navigation__list}>
                    <li className={style.navigation__list__item}><a href="#promo" className={style.navigation__link}>{t("footer.nav_1")}</a></li>
                    <li className={style.navigation__list__item}><a href="#services" className={style.navigation__link}>{t("footer.nav_2")}</a></li>
                    <li className={style.navigation__list__item}><a href="#about" className={style.navigation__link}>{t("footer.nav_3")}</a></li>
                </ul>

                <ul className={style.social__list}>
                    <li className={style.social__list__item}>
                        <a href="mailto:digital@aroundgroup.ru" className={style.social__link}>
                            <img src={email} alt="" className={style.social__list__item__img} />
                        </a>
                    </li>
                    <li className={style.social__list__item}>
                        <a href="https://t.me/around_work" className={style.social__link}>
                            <img src={telegram} alt="" className={style.social__list__item__img} />
                        </a>
                    </li>
                    <li className={style.social__list__item}>
                        <a href="https://vk.com/around_group" className={style.social__link}>
                            <img src={vk} alt="" className={style.social__list__item__img} />
                        </a>
                    </li>
                    <li className={style.social__list__item}>
                        <a href="https://www.youtube.com/@AROUNDgroup" className={style.social__link}>
                            <img src={youtube} alt="" className={style.social__list__item__img} />
                        </a>
                    </li>
                    <li className={style.social__list__item__mail}><a href="mailto:digital@aroundgroup.ru" className={style.social__link}>{t("footer.mail")}</a></li>
                    <li className={style.social__list__item__tel}><a href="tel:+79687442344" className={style.social__link}>{t("footer.tel")}</a></li>
                </ul>
            </footer>
        )
}

export default Footer;