const  likeDescrText = {
    eng: {
        likeDescrLike:" likes",
        likeDescr:"Some companies have already used our services" 
    },

    ru: {
        likeDescrLike:" Лайков",
        likeDescr:"Кто-то воспользовался нашими услугами" 
    }
}

export default likeDescrText