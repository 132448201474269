import React, { Component } from "react";
import PropTypes  from "prop-types"


export default class ServicesCaseTab extends Component {
    render() {
        const { text, className, onActive, active, index } = this.props;
        return (
            <li className = { `${className} ${active}` } onClick = { () => onActive(index) }>
                { text }
            </li>
        )
    }
}

ServicesCaseTab.propTypes = {
    itemKey: PropTypes.string,
    text: PropTypes.string,
    className: PropTypes.string,
    onActive: PropTypes.func,
    active: PropTypes.string,
    index: PropTypes.number
}
