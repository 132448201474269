import React, { Component } from "react";
import style from "./like_section.module.css";
import LikeButton from "./like_button/like_button";
import LikeDescr from "./like_descr/like_descr";


class LikeSection extends Component {

    constructor(props) { 
        super(props);
        this.state = {
            likeNumber: "",
            likeActive: false
        };
    }

    getLikeNumber = async () => {
        try {
            const response = await fetch('https://45.130.43.173:3001/like_vps');
 
            const data = await response.json();
            return(data.like_number);

        } catch (e) { 
            return 876;

        }  
 
    }  
 
    writeLikeNumber = async (like_number) => { 
        try {
            // Отправляем данные на сервер 
            const response = await fetch('https://45.130.43.173:3001/like_vps', {
              method: 'POST', 
              headers: {
                'Content-Type': 'application/json',
              }, 
              body: JSON.stringify({ like_number: like_number }), 
            });  
         
          } catch (error) {
            console.error('Произошла ошибка:', error);
          }
    }

    componentDidMount = async () => {
        if(localStorage.getItem('likeActive')) {
            await this.setState(() => ({
                likeActive: true
            }))
        }
        const like_number = await this.getLikeNumber();
        this.setState(() => ({
            likeNumber: like_number
        }));


    }

    changeLike = async () => {

        await this.setState(({ likeNumber, likeActive }) => {
            const likeButtonData = {
                sum: 1
            }
            if (likeActive) {
                likeButtonData.sum = -1;
                localStorage.removeItem("likeActive");
            } else {
                localStorage.setItem("likeActive", true);
            }
            return {
                likeNumber: likeNumber + likeButtonData.sum,
                likeActive: !likeActive,
            }
        }
        )

        this.writeLikeNumber(this.state.likeNumber);

    }

    render() {
        const { likeNumber, likeActive } = this.state;
        return (
            <div className={style.like__wrapper}>
                <LikeButton changeLike={this.changeLike} likeActive={likeActive} />
                <LikeDescr likeNumber={likeNumber} />
            </div>
        )
    }

} 

export default LikeSection;     