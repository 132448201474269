import React, { Component } from "react";
import PropTypes from "prop-types"
import style from "./service_list.module.css"
import { CSSTransition } from 'react-transition-group';


export default class ServiceList extends Component {
    constructor(props) {
        super(props);
        this.listItemRef = React.createRef();
    }
    createServiceList(listItemText, servicesList, content, animation_delay, CSSTransitionState) {
        animation_delay = `${animation_delay}s` ;
            return (
                <CSSTransition
                    in={CSSTransitionState}
                    timeout={{ enter: 1700, exit: 0 }} // Продолжительность анимации в миллисекундах
                    classNames={{
                        enter: style.service_list_item_enter,
                        enterActive: style.service_list_item_active,
                        exit: style.service_list_item_exit,
                        exitActive: style.service_list_item_exit_active,
                    }}
                    unmountOnExit
                >
                    <li  ref={this.listItemRef} className={style.service_list_item} style={{transitionDelay: animation_delay}}>
                    {listItemText}
                </li>
                </CSSTransition>
            )
    }

    render() {
        let { listItemText, servicesList, content, animation_delay, CSSTransitionState } = this.props;
        

        return (
            <>{this.createServiceList(listItemText, servicesList, content, animation_delay, CSSTransitionState)}</>
        )
    }
}

ServiceList.propTypes = {
    listItemText: PropTypes.string,
    servicesList: PropTypes.array,
    content: PropTypes.object,
    showServiceList: PropTypes.bool,
    animation_delay: PropTypes.number,
    CSSTransitionState: PropTypes.bool
}