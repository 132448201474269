import React, { useState, useEffect } from "react";
import style from "./promo_buttons.module.css";
import { useTranslation } from 'react-i18next';
import { CSSTransition } from 'react-transition-group'
import { useInView } from "react-intersection-observer";

const ProjectBtn = () => {
    const { t } = useTranslation();

    const [isButtunVisible, setButtonVisible] = useState(false);

    const [ref, inView] = useInView({triggerOnce: true});

    useEffect(() => {
        if (inView) {
            setButtonVisible(true);
        }
    }, [inView]);

    return (
            <CSSTransition in={isButtunVisible}
            timeout={2200}
            classNames={{
                enter: style.btnEnter,
                enterActive: style.btnProjectEnterActive
            }}
            >
                <a href="#form" ref={ref} className={`${style.btn} ${style.btn_project}`}>
                    {t("projectBtn")}
                </a>
            </CSSTransition>
        )
    }




export default ProjectBtn;