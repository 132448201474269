import React, { Component, useState, useEffect } from "react";
import style from "./services_case.module.css";
import appStyle from "../App.module.css";
import { withTranslation } from 'react-i18next';
import { useTranslation } from 'react-i18next';
import ServicesCaseList from "./services_cases_list/services_case_list";
import ServicesContent from "./services_content/services_content";
import PropTypes from "prop-types"
import serviceImg1 from "../img/servisesImg/4.png"
import serviceImg2 from "../img/servisesImg/2.png"
import serviceImg3 from "../img/servisesImg/1.png"
import serviceImg4 from "../img/servisesImg/3.png"
import serviceImg5 from "../img/servisesImg/5.png"
import CasesContent from "./cases_content/cases_content";
import { CSSTransition } from 'react-transition-group'
import { useInView } from 'react-intersection-observer';


const HeaderComponent = () => {
    const { t } = useTranslation();
    const [isHeaderVisible, setHeaderVisible] = useState(false);
    const [ref, inView] = useInView({
        triggerOnce: true // Опция, чтобы сработало только один раз
    });

    useEffect(() => {
        if (inView) {
            setHeaderVisible(true);
        }
    }, [inView]);


    return (
        <div ref={ref}>
            <CSSTransition in={isHeaderVisible}
                timeout={3500}
                classNames={{
                    enter: appStyle.titleEnter,
                    enterActive: appStyle.titleEnterActive
                }}
            >
                <h2 className={appStyle.header}>{t("servicesCaseHeader")}</h2>
            </CSSTransition>
        </div>
    )
}

class ServicesCase extends Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef();
        this.imgList = [
            serviceImg1,
            serviceImg2,
            serviceImg3,
            serviceImg4,
            serviceImg5,
        ];

        this.allServiceList = [[],[],[],[],[]]
        this.allDescriptionData = []
        this.state = {
            activeIndex: 1,  // запоминаю номер таба, который будет активен
            showServiceList: false,
            
        }
    }
    createServiseAllContent() {
        const { t } = this.props;
        this.allDescriptionData = []
        this.allServiceList = [[],[],[],[],[]]

        for(let i = 0; i <= 5; ++i){
            let j = 1;

            while (j) {
                if (t(`servicesContent.service${i}.serviceList.item${j}`) === `servicesContent.service${i}.serviceList.item${j}`) {
                    break;
                }
    
                this.allServiceList[i-1].push(t(`servicesContent.service${i}.serviceList.item${j}`));
                j++;
            }
            this.allDescriptionData.push(t(`servicesContent.service${i}.serviceDescription`))
        }
                // console.log(this.allDescriptionData)


    }
  

    //меняю в стейте номер активного таба
    onActive = (index) => {
        this.setState((prevState) => ({
            activeIndex: index + 1, // по клику меняю номер таба на актуальный
            showServiceList: !prevState.showServiceList
        }))


        this.createServiceContent()

    }



    createTabList = () => {
        const { t } = this.props;
        const tabListData = [];
        //Собираю базу моих табов из текстов в i18n
        for (let i = 1; i <= 5; i++) {
            let active = false
            if (i === this.state.activeIndex) {
                active = true; //добавляю в бд, что этот элемент активен
            }
            tabListData.push({
                text: t(`servisesCaseTab.tab${i}`),
                active: active
            });
        }
        return tabListData
    }


    createServiceContent = () => {
        const { t } = this.props;
        const imgList = this.imgList;

        const activeIndex = this.state.activeIndex;
        const serviceContent = {
            serviceDescription: t(`servicesContent.service${activeIndex}.serviceDescription`),
            serviceList: [],
            servicePlan: [],
            serviceImg: imgList[activeIndex - 1]
        };
        let i = 1;
        let j = 1;
        while (i) {
            if (t(`servicesContent.service${activeIndex}.serviceList.item${i}`) === `servicesContent.service${activeIndex}.serviceList.item${i}`) {
                break;
            }

            serviceContent.serviceList.push(t(`servicesContent.service${activeIndex}.serviceList.item${i}`));
            i++;
        }

        while (j) {
            if (t(`servicesContent.service${activeIndex}.servicePlan.item${j}`) === `servicesContent.service${activeIndex}.servicePlan.item${j}`) {
                break;
            }

            serviceContent.servicePlan.push(t(`servicesContent.service${activeIndex}.servicePlan.item${j}`));
            j++;
        }



        return serviceContent
    }



    render() {
        this.createServiseAllContent()

        const { t } = this.props;
        return (
            <section className={style.servicecase}>
                <HeaderComponent />
                <div className={style.overlay}></div>

                <ServicesCaseList tabListData={this.createTabList()} onActive={this.onActive} />
                <div className={style.services_cases__wrapper}>
                    <ServicesContent content={this.createServiceContent()} imgList={this.imgList} showServiceList={this.state.showServiceList} allServiceList={this.allServiceList} allDescriptionData={this.allDescriptionData}/>
                    <CasesContent activePage ={this.state.activeIndex - 1}/>
                </div>


            </section>
        )
    }

}


ServicesCase.propTypes = {
    t: PropTypes.func
}



export default withTranslation()(ServicesCase);