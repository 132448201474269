const  promoDescrText = {
    eng: {
        promoDescr:"Integrated solutions for web development and design, SMM, promotion and video production. ",
        promoMoto: "We know how to attract and retain your customers!",
        

    },
    ru: {
        promoDescr:"Комплексные решения в сфере разработки сайтов, дизайна, SMM, продвижения и видео продакшна",
        promoMoto: "Знаем, как привлечь и удержать ваших клиентов!" 
    }
}

export default promoDescrText