const  servisesCaseTab = {

    eng: {
        tab1:"Web development",
        tab2:"Web design",
        tab3:"SMM",
        tab4:"Promotion",
        tab5:"Video production"
    },
    
    ru: {
        tab1:"Разработка сайтов",
        tab2:"Дизайн",
        tab3:"SMM",
        tab4:"Продвижение",
        tab5:"Видеопродакшн"
    }
}

export default servisesCaseTab