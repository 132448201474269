import React, { Component, useRef } from 'react';
// import './App.css';
import style from './App.module.css';
import Header from './app-header/app-header';
import Promo from './promo/promo';
import Menu from './menu/menu';
import ServicesCase from './services_cases/services_case';
import About from './about/about';
import Crew from './crew/crew';
import Form from './form/form';
import Footer from './footer/footer';
import globalStyle from './globalStyles.module.css'
import { RevolvingDot } from 'react-loader-spinner'

import logo_magenta from "./img/logo_magenta.svg"
import AnimatedText from './services_cases/services_content/animated_text/script';
import AnimatedtextStyle from "./descr_animation_style.module.css"
import i18next from 'i18next';

class App extends Component {
	constructor (props) {
		super(props);
		this.formRef = React.createRef()

		this.state = {
			menuActive: false
		}
	}
	scrollToElement = (elementRef) => {
		window.scrollTo(0, elementRef.current);
	}

	openMenu = () => {
		this.setState(() => ({
			menuActive: true
		}))
	}


	closeMenu = () => {
		this.setState(() => ({
			menuActive: false
		}))
	}

	cheackUserLang = async () =>{
		if (localStorage.getItem("lang") === "eng") {
			await i18next.changeLanguage("eng")
		}
	}

	render() {
		this.cheackUserLang();
		let visible_window_class = style.visible_window;
		let container_class = style.container;
		const { menuActive } = this.state;

		if (menuActive){
			visible_window_class += ` ${style.visible_window_menu_active}`;
			container_class += ` ${style.container_menu_active}`;
		}


		const formRef = this.formRef;
		return (
			<div className={`${visible_window_class} ${globalStyle}`}>
					<Menu active = {menuActive} closeMenu = {this.closeMenu}/>

				<div className={style.preloalder_circle_wrapper}>
					{/* <RevolvingDot
						radius="45"
						strokeWidth="5"
						color="#F8177C"
						secondaryColor='#F8177C'
						ariaLabel="revolving-dot-loading"
						wrapperStyle={{}}
						wrapperClass=""
						visible={true}
					/> */}
				</div>

				<div className={`${container_class}`} >
					<Header scrollToElement = {() => this.scrollToElement(formRef)} openMenu = {this.openMenu}/>
					
					<Promo />
					<ServicesCase />
					<About />
					<Crew />
					<Form ref = {formRef}/>
					<Footer />
				</div>
			</div>

		)
	}
}

export default App;
