const  aboutInformation = {
    ru: {
        aboutDesc: "Мы - команда профессионалов, которые помогут создать эффективную digital-стратегию для вашего бизнеса на любом этапе его развития. В нашей команде опытные разработчики, талантливые дизайнеры и креативные маркетологи, которые готовы применить свой опыт и навыки для решения самых сложных задач. Мы с удовольствием возьмем на себя проекты любой сложности и масштаба.",
        adventage1: "Более <span>7 лет </span>на рынке",
        adventage2: "<span>Успешный опыт работы</span> с крупнейшими российскими и зарубежными компаниями",
        adventage3: "<span>Индивидуальный подход</span> к каждому проекту",
    },
    eng: {
        aboutDesc: "We are the team of professionals that will help to develop an effective digital strategy at any stage of your business. Our experienced web developers, talented designers and clever marketers are ready to bring all their skills to perform the most complex tasks of any scale.",
        adventage1: "<span>7 years </span>on the market",
        adventage2: "<span>Extensive and successful experience</span> with large Russian and western companies",
        adventage3: "<span>Personal approach</span> for any project",
    }
}

export default aboutInformation