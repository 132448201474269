import React, { Component } from "react";
import PropTypes from "prop-types"
import style from "./cases_content.module.css"
import basicStyle from "../../App.module.css"
import arrow from "../../img/arrow.svg"
import { withTranslation } from "react-i18next";
import Tilt from 'react-parallax-tilt';
// import caseData from './cases_content_data';
import { CSSTransition } from 'react-transition-group';
import logo_2_1 from "../../img/case/page_2/1.svg"
import logo_5_1 from "../../img/case/page_5/1.svg"
import logo_4_1 from "../../img/case/page_4/1.png"
import logo_1_1 from "../../img/case/page_1/1.png"
import logo_3_1 from "../../img/case/page_3/1.svg"


class CaseItem extends Component {

    render() {
        const { t, logo, openModal } = this.props
        return (
            <div className={style.cases_element} onClick={openModal}>
                <img src={logo} alt={logo} className={style.case_logo} />

                <div className={style.details_wrapper}>
                    <div className={style.details_text}>{t("casesContent.headers.casesDetails")}</div>
                    <img src={arrow} alt="arrow" className={style.arrow} />

                </div>
            </div>
        )
    }
}

class CaseModal extends Component {
    createCaseCreo(content) {
        let imgs = [] 

        for (let i = 1; i <= 2; i++) {
            if (!/(jpg|png)/.test(content[`creo_${i}`])) {
                console.log(content[`creo_${i}`])
            } else {
                imgs.push(<img className = {basicStyle.case_creo} src={content[`creo_${i}`]}/>)
            }
        }

        return (
            <div className={basicStyle.case_creo_wrapper}>
                {imgs}
            </div>
        )
    }
    render() {
        const { t, content, closeModal, activeModal } = this.props;
        const activeClass = activeModal ? basicStyle.modal_active : ""
        return (
            <div className={`${basicStyle.modal_wrapper} ${activeClass}`}>
                <div className={basicStyle.modal}>
                    <h3 className={basicStyle.modal_header}>{content.header}</h3>
                    {this.createCaseCreo(content)}
                    <h4 className={basicStyle.modal_paragraph_header}>{t("casesContent.headers.summary")}</h4>
                    <div className={basicStyle.modal_about} dangerouslySetInnerHTML={{ __html: content.about }} ></div>

                    <h4 className={basicStyle.modal_paragraph_header}>{t("casesContent.headers.profile")}</h4>
                    <div className={basicStyle.modal_profile} dangerouslySetInnerHTML={{ __html: content.profile }} ></div>

                    <h4 className={basicStyle.modal_paragraph_header}>{t("casesContent.headers.description")}</h4>
                    <div className={basicStyle.modal_description} dangerouslySetInnerHTML={{ __html: content.description }}></div>

                    <h4 className={basicStyle.modal_paragraph_header}>{t("casesContent.headers.goals")}</h4>
                    <div className={basicStyle.modal_goals} dangerouslySetInnerHTML={{ __html: content.goals }} />

                    <h4 className={basicStyle.modal_paragraph_header}>{t("casesContent.headers.results")}</h4>
                    <div className={basicStyle.modal_results} dangerouslySetInnerHTML={{ __html: content.results }}></div>

                    <h4 className={basicStyle.modal_paragraph_header}>{t("casesContent.headers.total")}</h4>
                    <div className={basicStyle.modal_total} dangerouslySetInnerHTML={{ __html: content.total }}></div>
                    <div className={basicStyle.close_wrapper} onClick={closeModal}><div className={basicStyle.close} /></div>
                </div>
            </div>
        )
    }
}
class CasesContent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeModal: {
                "page_0.index_0": false,
                "page_1.index_0": false,
                "page_2.index_0": false,
                "page_3.index_0": false,
                "page_4.index_0": false,
            }
        }
    }

    closeModal(activePage, index) {
        this.setState((prevState) => ({
            activeModal: {
                ...prevState.activeModal,
                [`page_${activePage}.index_${index}`]: false
            }
        }))
    }

    openModal(activePage, index) {
        // console.log(`activePage ${activePage}`)
        // console.log(`index ${index}`)
        this.setState((prevState) => ({
            activeModal: {
                ...prevState.activeModal,
                [`page_${activePage}.index_${index}`]: true
            }
        }))

    }

    createCasesData(t) {
        const casesData = [[], [], [], [], []]

        for (let i = 1; i <= 5; ++i) {
            let j = 1;
            while (j) {
                if (t(`casesContent.tab_${i}.case_${j}.header`) === `casesContent.tab_${i}.case_${j}.header`) {
                    break;
                }
                const caseContent = {}

                caseContent.header = t(`casesContent.tab_${i}.case_${j}.header`)
                caseContent.about = t(`casesContent.tab_${i}.case_${j}.about`)
                caseContent.profile = t(`casesContent.tab_${i}.case_${j}.profile`)
                caseContent.description = t(`casesContent.tab_${i}.case_${j}.description`)
                caseContent.goals = t(`casesContent.tab_${i}.case_${j}.goals`)
                caseContent.results = t(`casesContent.tab_${i}.case_${j}.results`)
                caseContent.total = t(`casesContent.tab_${i}.case_${j}.total`)
                caseContent.logo = t(`casesContent.tab_${i}.case_${j}.logo`)
                caseContent.creo_1 = t(`casesContent.tab_${i}.case_${j}.creo_1`)
                caseContent.creo_2 = t(`casesContent.tab_${i}.case_${j}.creo_2`)
                casesData[i - 1].push(caseContent)

                j++

            }

        }

        return casesData


    }

    createCasesList = (t, activePage) => {
        const caseData = this.createCasesData(t)
        const logos = [logo_2_1, logo_5_1, logo_4_1, logo_1_1, logo_3_1]

        const casesToRender = caseData.map((page, i) => {
            let CSSTransitionState = false;
            if (activePage === i) {
                CSSTransitionState = true;
            }

            const casesListOnPage = page.map((caseItem, index) => {
                let activeClass = false;
                // console.log(caseItem)

                if (this.state.activeModal[`page_${i}.index_${index}`]) {
                    // console.log(this.state.activeModal[`page_${i}.index_${index}`])
                    activeClass = true
                }
 

                return (

                    <>
                        <CSSTransition
                            key={`CSSTransition_${i}_${index}`}
                            in={CSSTransitionState}
                            timeout={0} // Продолжительность анимации в миллисекундах
                            classNames={{
                                // enter: style.cases_element_enter,
                                // enterActive: style.cases_element_active,
                                // exit: style.cases_element_exit,
                                // exitActive: style.cases_element_exit_active
                            }}
                            unmountOnExit
                        >
                            <Tilt key={`tilt_${i}`}
                                tiltMaxAngleX={10}
                                tiltMaxAngleY={5}
                            >
                                <CaseItem openModal={() => this.openModal(activePage, index)} key={i} t={t} active={CSSTransitionState} logo={caseItem.logo} />
                            </Tilt>
                        </CSSTransition>
                        <CaseModal t={t} key={index} content={caseItem}
                            closeModal={() => this.closeModal(activePage, index)}
                            activeModal={activeClass}
                        />
                    </>

                )

            })

            return casesListOnPage;
        })



        return casesToRender
    }

    render() {
        const { t, activePage } = this.props
        return (
            <div className={style.cases_wrapper}>
                {this.createCasesList(t, activePage)}
            </div>
        )
    }
}

CasesContent.propTypes = {
    content: PropTypes.object,
    t: PropTypes.func,
    activePage: PropTypes.number
}

CaseItem.propTypes = {
    t: PropTypes.func,
    logo: PropTypes.string,
    openModal: PropTypes.func
}

CaseModal.propTypes = {
    t: PropTypes.func,
    content: PropTypes.object,
    closeModal: PropTypes.func,
    activeModal: PropTypes.bool
}

export default withTranslation()(CasesContent);

