import React, { Component } from "react";
import style from "./like_button.module.css";
import likeRotateText from "../../../img/like_img/like_rotate_text.svg"
import likeBG from "../../../img/like_img/like_bg.svg"
import HeartImg from "./heart_icon"
import PropTypes from "prop-types";

class LikeButton extends Component {
    render() {
        const {changeLike, likeActive} = this.props;
        let heartColor = "#fff";

        if (likeActive) {
            heartColor = "#FD292F";
        }
        let activeClass = "";
        
        return (
            <div className={style.like__button__wrapper} onClick={changeLike}>
                <img src={likeRotateText} alt="text" className={style.like__rotate__text}/>
                <img src={likeBG} alt="likebg" className={style.like__bg}/>
                <div alt="heart" className={`${style.heart} ${activeClass}`}>
                    <HeartImg heartColor = {heartColor}/>
                </div>
            </div>
        )
    }
}

LikeButton.propTypes = {
    changeLike: PropTypes.func,
    likeActive: PropTypes.bool,
    heartColor: PropTypes.string
}



export default LikeButton;