const formText = {
    ru: {
        formHeader: "Оставить заявку",
        name: "ФИО",
        phone: "Номер",
        mail: "E-mail",
        comment: "Комментарий",
        confidatial: "Даю согласие на обработку моих персональных данных, соглашаюсь с политикой конфиденциальности и рассылкой",
        submit: "Отправить"
    },
    eng: {
        formHeader: "Get in touch",
        name: "Name",
        phone: "Phone number",
        mail: "E-mail",
        comment: "Message",
        confidatial: "Yes, I give my consent to the processing of my personal data and the use of e-mail communications - in accordance with the Privacy Policy - to process and respond to this request.",
        submit: "Send"
    }
}

export default formText